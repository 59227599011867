import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import Cookie from "js-cookie";

import { storage } from "utils";

export default (): null => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const isIframe = window.location !== window.parent.location;
    if (!isIframe) {
      const testActivation = searchParams.get("test");

      if (testActivation === "true")
        Cookie.set("dp-test-cookie", JSON.stringify({ active: true }));

      if (testActivation === "false")
        Cookie.set("dp-test-cookie", JSON.stringify({ active: false }));
    }

    // const referrer =
    //   searchParams.get("referrer") ??
    //   searchParams.get("gclid") ??
    //   searchParams.get("fbclid");
    // if (referrer)
    //   Cookie.set("referrer", JSON.stringify({ referrer }), { expires: 7 });

    // const occupation = searchParams.get("occupation");
    // if (occupation) storage.setSession("occupation", occupation);

    const feature = searchParams.get("feature");
    if (feature) storage.setLocal("feature", feature);
  }, [searchParams]);

  return null;
};
