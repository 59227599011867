import { RouteProps } from "react-router-dom";

import {
  IframeSignup,
  Login,
  MagicLink,
  MagicLinkVerification,
  SignupPerson,
  SignupTeam,
  TierSelection,
} from "components/login/pages";
import { NameSpace } from "translations/shared";
import { getRoutePath } from "utils";

export default (): {
  ns: NameSpace;
  routes: RouteProps[];
  loggedInRoutes: RouteProps[];
  loggedOutRoutes: RouteProps[];
} => {
  // prettier-ignore
  const routes: RouteProps[] = [
    { path: getRoutePath("login","MAGIC_LINK_VERIFICATION"), element: <MagicLinkVerification /> },
    { path: getRoutePath("login","TIER_SELECTION", ":module"), element: <TierSelection /> },
    { path: getRoutePath("login","TIER_SELECTION"), element: <TierSelection /> },
    { path: getRoutePath("login","IFRAME_SIGNUP", ":vertical"), element: <IframeSignup /> },
  ];

  // prettier-ignore
  const loggedInRoutes: RouteProps[] = [];

  // prettier-ignore
  const loggedOutRoutes: RouteProps[] = [
    { path: getRoutePath("login","LOGIN"), element: <Login /> },
    { path: getRoutePath("login","SIGNUP_PERSON"), element: <SignupPerson /> },
    { path: getRoutePath("login","SIGNUP_TEAM", ":plan"), element: <SignupTeam /> },
    { path: getRoutePath("login","SIGNUP_TEAM"), element: <SignupTeam /> },
    { path: getRoutePath("login","MAGIC_LINK_LOGIN"), element: <MagicLink /> },
  ];

  return { ns: "login", routes, loggedInRoutes, loggedOutRoutes };
};
