import { useContext, useState } from "react";
import {
  Control,
  Controller,
  FieldErrors,
  Resolver,
  UseFormRegister,
  useForm,
} from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { Button, Divider, Input, Select } from "components/common/basic";
import {
  ReferencePeopleSection,
  ReferencePeopleSectionOrganization,
} from "components/common/composite";
import { ClientFragment, ReferencePersonFragment } from "graphql/fragments";
import {
  useCreateClientMutation,
  useUpdateClientMutation,
} from "graphql/mutations";
import { AddressAttributes, ClientTypeEnum } from "graphql/types";
import { useErrorLogger } from "hooks";
import { IntlContext } from "providers/i18n";
import { Trans, useTranslation } from "translations";
import {
  emailValidationSchema,
  getCountryNameFromCode,
  getNonSanctionedCountries,
  phoneValidationSchema,
  referencePersonToAttributes,
} from "utils";
import { tw } from "utils/tw";

import { AddClientContext } from "./AddClient";

interface FormValues extends ClientFragment {
  orgNumber: string;
  email: string;
  address: AddressAttributes;
}

export interface Props {
  formId: string;
  onSuccessfulEdit: (client: ClientFragment) => void;
  client: ClientFragment;
}

export default ({ formId, onSuccessfulEdit, client }: Props): JSX.Element => {
  const hasIncompleteAddress =
    !client.address?.line1 ||
    !client.address?.postalCode ||
    !client.address?.city ||
    !client.address?.country;
  const [isEditingAddress, setIsEditingAddress] =
    useState(hasIncompleteAddress);

  const { createClient } = useCreateClientMutation();
  const { updateClient } = useUpdateClientMutation();
  const { freelanceProfileId } = useContext(AddClientContext);
  const { currentLocale } = useContext(IntlContext);
  const { t } = useTranslation("common");

  // TODO: Get from BE
  const countryCodes = getNonSanctionedCountries();

  const onSubmit = (values: FormValues) => {
    const {
      id,
      address,
      referencePeople,
      preferredDeliveryMethod,
      ...clientWithoutAddress
    } = values;

    const attributes = {
      ...clientWithoutAddress,
      referencePeople: id
        ? undefined
        : referencePeople.map(referencePersonToAttributes),
      preferredDeliveryMethod,
      address: {
        ...address,
        line1: address?.line1 ?? "",
        postalCode: address?.postalCode ?? "",
        city: address?.city ?? "",
        country: address?.country?.toUpperCase() ?? "",
      },
    };

    if (id) {
      const { clientType, ...updateAttributes } = attributes;

      updateClient({ id, attributes: updateAttributes }, onSuccessfulEdit);
    }

    if (freelanceProfileId) {
      const createAttributes = { ...attributes, freelanceProfileId };

      createClient({ attributes: createAttributes }, onSuccessfulEdit);
    }
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .trim()
      .required(
        t("clientForm_organization.name.error.required", "A name is required")
      ),
    orgNumber: Yup.string()
      .trim()
      .required(
        t(
          "clientForm_organization.orgNumber.error.required",
          "An organization number is required"
        )
      ),
    address: Yup.object({
      line1: Yup.string()
        .trim()
        .required(
          t(
            "clientForm_organization.address.line1.error.required",
            "Address line 1 is required"
          )
        ),
      postalCode: Yup.string()
        .trim()
        .required(
          t(
            "clientForm_organization.address.postalCode.error.required",
            "A post code is required"
          )
        ),
      city: Yup.string()
        .trim()
        .required(
          t(
            "clientForm_organization.address.city.error.required",
            "A city is required"
          )
        ),
      country: Yup.string()
        .required(
          t(
            "clientForm_organization.address.country.error.required",
            "A country is required"
          )
        )
        .oneOf(
          countryCodes,
          t(
            "clientForm_organization.address.country.error.oneOf",
            "A valid country is required"
          )
        ),
    }),
    referencePeople: Yup.array()
      .of<ReferencePersonFragment>(
        Yup.object({
          name: Yup.string()
            .trim()
            .required(
              t(
                "clientForm_organization.referencePerson.name.error.required",
                "A name is required for a reference person"
              )
            ),
          email: emailValidationSchema(t).required(),
          phone: phoneValidationSchema(t),
          referenceNumber: Yup.string(),
        })
      )
      .required(),
    email: emailValidationSchema(t).required(),
    clientNumber: Yup.number()
      .max(
        99999999,
        t(
          "clientForm_organization.clientNumber.error.max",
          "Client number must be between 1 and 8 digits"
        )
      )
      .integer(
        t(
          "clientForm_organization.clientNumber.error.integer",
          "Client number can not include punctuation"
        )
      ),
  });

  const defaultValues = {
    id: client.id,
    clientType: ClientTypeEnum.Organization,
    nationalRegisterCheck: client.nationalRegisterCheck,
    name: client.name,
    orgNumber: client.orgNumber ?? "",
    address: {
      line1: client.address?.line1 ?? "",
      line2: client.address?.line2 ?? "",
      postalCode: client.address?.postalCode ?? "",
      city: client.address?.city ?? "",
      county: client.address?.county ?? "",
      country: client.address?.country ?? "",
    },
    referencePeople: client.referencePeople?.length
      ? client?.referencePeople.map(referencePersonToAttributes)
      : [],
    friendlyName: client.friendlyName ?? "",
    email: client.email ?? "",
    clientNumber: client.clientNumber,
    preferredDeliveryMethod: client.preferredDeliveryMethod,
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<FormValues>({
    // TODO: Investigate type mismatch (error does not specify which part is incompatible)
    resolver: yupResolver(validationSchema) as unknown as Resolver<FormValues>,
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues,
  });
  const { reportErrors } = useErrorLogger();
  reportErrors(errors);

  return (
    <form id={formId} onSubmit={handleSubmit(onSubmit)}>
      {[
        "clientType",
        "nationalRegisterCheck",
        "id",
        "name",
        "orgNumber",
        "preferredDeliveryMethod",
        "address.line1",
        "address.line2",
        "address.postalCode",
        "address.city",
        "address.county",
        "address.country",
      ].map((name) => (
        <input
          key={name}
          {...register(name as keyof ClientFragment)}
          style={{ display: "none" }}
        />
      ))}

      <div className={tw("w-full", "flex", "flex-col", "space-y-4")}>
        <div className={tw("flex", "flex-col", "space-y-4")}>
          <h2 className={tw("text-lg", "font-bold")}>
            {t(
              "clientForm_organization.infoSection.title",
              "Organisation info"
            )}
          </h2>

          <Input
            id="name"
            {...register("name")}
            label={t("clientForm_organization.name.label", "Organisation name")}
            errorMessage={errors.name?.message}
            disabled={client.nationalRegisterCheck}
            autoFocus
          />

          <Input
            id="orgNumber"
            {...register("orgNumber")}
            label={t(
              "clientForm_organization.orgNumber.label",
              "Organisation number"
            )}
            errorMessage={errors.orgNumber?.message}
            disabled={client.nationalRegisterCheck}
          />

          <Divider />

          <div className={tw("flex", "flex-col", "space-y-4")}>
            <h2 className={tw("text-lg", "font-bold")}>
              {t(
                "clientForm_organization.addressSection.title",
                "Organisation address"
              )}
            </h2>

            {!isEditingAddress && (
              <Button
                id="create_org_client_form-edit_address"
                variant="tertiary"
                size="sm"
                onClick={() => setIsEditingAddress(true)}
              >
                {t(
                  "clientForm_organization.addressSection.button.edit",
                  "Edit address"
                )}
              </Button>
            )}
          </div>

          <div className={isEditingAddress ? tw("space-y-4") : undefined}>
            {[
              { name: "line1" as const, defaultLabel: "Address" },
              { name: "line2" as const, defaultLabel: "Address line 2" },
              { name: "postalCode" as const, defaultLabel: "Post code" },
              { name: "city" as const, defaultLabel: "City" },
              { name: "county" as const, defaultLabel: "County" },
            ].map(({ name, defaultLabel }) => {
              if (!isEditingAddress)
                return <p key={name}>{client.address?.[name]}</p>;

              const label = t(
                `clientForm_organization.addressSection.${name}.label`,
                defaultLabel
              );
              // Values for extraction
              // t("account:clientForm_organization.addressSection.line1.label", "Address")
              // t("account:clientForm_organization.addressSection.line2.label", "Address line 2")
              // t("account:clientForm_organization.addressSection.postalCode.label", "Post code")
              // t("account:clientForm_organization.addressSection.city.label", "City")
              // t("account:clientForm_organization.addressSection.county.label", "County")

              const inputProps = {
                errorMessage: errors.address?.[name]?.message,
                // TODO: Find a way to prevent typecasting address
                ...register(`address.${name}` as unknown as "address"),
              };

              return (
                <Input
                  key={name}
                  id={"address." + name}
                  label={label}
                  {...inputProps}
                />
              );
            })}

            {isEditingAddress ? (
              <Controller
                control={control}
                name="address.country"
                defaultValue={client.address?.country ?? undefined}
                render={({ field }) => (
                  <Select
                    id={field.name}
                    name={field.name}
                    label={t(
                      "clientForm_organization.addressSection.country.label",
                      "Country"
                    )}
                    options={[
                      {
                        label: t(
                          "clientForm_organization.addressSection.country.none",
                          "Select a country"
                        ),
                        value: "NOT_SELECTED",
                      },
                      ...countryCodes.map((countryCode) => ({
                        label: getCountryNameFromCode(
                          countryCode,
                          currentLocale
                        ),
                        value: countryCode,
                      })),
                    ]}
                    value={field.value ?? undefined}
                    onChange={field.onChange}
                    errorMessage={errors.address?.["country"]?.message}
                    disabled={client.nationalRegisterCheck}
                  />
                )}
              />
            ) : (
              <p>
                {client.address?.country &&
                  getCountryNameFromCode(client.address.country, currentLocale)}
              </p>
            )}
          </div>
        </div>

        {!client.id && (
          <>
            <div className={tw("flex", "flex-col", "space-y-4")}>
              <Divider />

              <h2 className={tw("my-4", "text-lg", "font-bold")}>
                {t(
                  "clientForm_organization.referencePeopleSection.title",
                  "Reference persons"
                )}
              </h2>

              <p>
                <Trans
                  ns="account"
                  i18nKey="clientForm_organization.referencePeopleSection.heading"
                  defaults="Please provide the name of your contact at <0>{{ orgName }}</0>. Your invoices will mention this person information."
                  values={{ orgName: client.orgNumber }}
                  components={[<strong />]}
                />
              </p>
            </div>

            <ReferencePeopleSection
              // TODO: Find a way to prevent typecasting
              control={
                control as unknown as Control<ReferencePeopleSectionOrganization>
              }
              register={
                register as unknown as UseFormRegister<ReferencePeopleSectionOrganization>
              }
              referencePeopleErrors={errors.referencePeople as FieldErrors}
            />
          </>
        )}

        <div className={tw("flex", "flex-col", "space-y-4")}>
          <Divider />

          <h2 className={tw("my-4", "text-lg", "font-bold")}>
            {t(
              "clientForm_organization.invoiceSection.title",
              "Organisation invoice details"
            )}
          </h2>

          <p>
            {t(
              "clientForm_organization.invoiceSection.heading",
              "Please provide the email address where we should send invoices for this customer. This is either the finance department or the reference person mentioned above."
            )}
          </p>

          <Input
            id="friendlyName"
            {...register("friendlyName")}
            label={t(
              "clientForm_organization.friendlyName.label",
              "Friendly name"
            )}
            hint={t("clientForm_organization.friendlyName.hint", "Optional")}
            errorMessage={errors.friendlyName?.message}
          />

          <Input
            id="email"
            {...register("email")}
            type="email"
            label={t("clientForm_organization.email.label", "Invoice email")}
            errorMessage={errors.email?.message}
          />

          <Input
            id="clientNumber"
            {...register("clientNumber")}
            label={t(
              "clientForm_organization.clientNumber.label",
              "Client number"
            )}
            errorMessage={errors.clientNumber?.message}
          />
        </div>
      </div>
    </form>
  );
};
