import React, { useContext } from "react";

import Select, { Props as SelectProps } from "components/common/basic/Select";
import { IntlContext } from "providers/i18n";
import { useTranslation } from "translations";
import { getCountryNameFromCode, getNonSanctionedCountries } from "utils";

interface Props extends Omit<SelectProps, "options" | "label"> {
  label?: string;
}

export default React.forwardRef(
  (props: Props, ref: React.ForwardedRef<HTMLSelectElement>): JSX.Element => {
    const { currentLocale } = useContext(IntlContext);
    const { t } = useTranslation("common");

    // TODO: Get from BE
    const countryCodes = getNonSanctionedCountries();

    return (
      <Select
        {...props}
        ref={ref}
        label={props.label ?? t("countrySelect.label", "Country")}
        options={[
          {
            label: t("countrySelect.none", "Select a country"),
            value: "NOT_SELECTED",
            isDisabled: Boolean(props.value),
          },
          ...countryCodes.map((countryCode) => ({
            label: getCountryNameFromCode(countryCode, currentLocale),
            value: countryCode,
          })),
        ]}
      />
    );
  }
);
