import { lazy } from "react";

const Login = lazy(() => import("./Login"));
const MagicLink = lazy(() => import("./MagicLink"));
const MagicLinkVerification = lazy(() => import("./MagicLinkVerification"));
const TierSelection = lazy(() => import("./TierSelection"));
const SignupPerson = lazy(() => import("./SignupPerson"));
const SignupTeam = lazy(() => import("./SignupTeam"));
const IframeSignup = lazy(() => import("./IframeSignup"));

export {
  Login,
  MagicLink,
  MagicLinkVerification,
  TierSelection,
  SignupPerson,
  SignupTeam,
  IframeSignup,
};
