export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  EmailField: { input: any; output: any; }
};

export type Account = Node & {
  __typename?: 'Account';
  accountType: AccountTypeEnum;
  address?: Maybe<Address>;
  canManage: Scalars['Boolean']['output'];
  canManageDepartments: Scalars['Boolean']['output'];
  country?: Maybe<Scalars['String']['output']>;
  counts?: Maybe<Counts>;
  defaultIdentity?: Maybe<Identity>;
  deletionScheduledOn?: Maybe<Scalars['String']['output']>;
  departmentMemberships: Array<DepartmentMembership>;
  description?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  features: LegacyFeatures;
  hasFreelanceProfiles: Scalars['Boolean']['output'];
  hasWhistleblowingAccess: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  identities?: Maybe<Array<Identity>>;
  industrySector?: Maybe<Scalars['String']['output']>;
  isWbHandler: Scalars['Boolean']['output'];
  language: LanguageEnum;
  limits?: Maybe<FeatureCounts>;
  logo?: Maybe<Attachment>;
  monogramPath?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nonProfit: Scalars['Boolean']['output'];
  organizationName?: Maybe<Scalars['String']['output']>;
  organizationNumber?: Maybe<Scalars['String']['output']>;
  owner?: Maybe<Account>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  policyDocuments: Array<Attachment>;
  pricePlan?: Maybe<PricePlanEnum>;
  primary: Scalars['Boolean']['output'];
  primaryUser?: Maybe<User>;
  registrationTier?: Maybe<Tier>;
  role?: Maybe<TeamRoleEnum>;
  routineGuidelines: Array<Attachment>;
  slug: Scalars['String']['output'];
  subscription?: Maybe<Subscription>;
  symbol?: Maybe<Attachment>;
  symbolDisplayPath?: Maybe<Scalars['String']['output']>;
  tag?: Maybe<AccountTagEnum>;
  theme: AccountTheme;
  uiFlags: UiFlags;
  usage?: Maybe<FeatureCounts>;
  wbSettings: WbSettings;
  wbSlug: Scalars['String']['output'];
  webhookUrl?: Maybe<Scalars['String']['output']>;
  website?: Maybe<Scalars['String']['output']>;
  whistleblowingStickerJpgDownloadPath?: Maybe<Scalars['String']['output']>;
  whistleblowingStickerPdfDownloadPath?: Maybe<Scalars['String']['output']>;
  whistleblowingStickerPngDownloadPath?: Maybe<Scalars['String']['output']>;
};

export type AccountPagination = {
  __typename?: 'AccountPagination';
  exportPath?: Maybe<Scalars['String']['output']>;
  items: Array<Account>;
  total: Scalars['Int']['output'];
};

export enum AccountTagEnum {
  Generic = 'GENERIC',
  HealthServiceYouth = 'HEALTH_SERVICE_YOUTH',
  Kindergarten = 'KINDERGARTEN',
  KindergartenAndSchool = 'KINDERGARTEN_AND_SCHOOL',
  LanguageService = 'LANGUAGE_SERVICE',
  MarchingBand = 'MARCHING_BAND',
  Porterbuddy = 'PORTERBUDDY',
  Religious = 'RELIGIOUS',
  Sports = 'SPORTS',
  Volunteer = 'VOLUNTEER'
}

export type AccountTheme = {
  __typename?: 'AccountTheme';
  font?: Maybe<AccountThemeFont>;
  primary?: Maybe<Scalars['String']['output']>;
  primaryContrast?: Maybe<Scalars['String']['output']>;
  primaryHover?: Maybe<Scalars['String']['output']>;
};

export type AccountThemeAttributes = {
  font?: InputMaybe<AccountThemeFontAttributes>;
  primary?: InputMaybe<Scalars['String']['input']>;
  primaryContrast?: InputMaybe<Scalars['String']['input']>;
  primaryHover?: InputMaybe<Scalars['String']['input']>;
};

export type AccountThemeFont = {
  __typename?: 'AccountThemeFont';
  name: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type AccountThemeFontAttributes = {
  name: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export enum AccountTypeEnum {
  Personal = 'PERSONAL',
  Team = 'TEAM'
}

export enum ActorEnum {
  Administrator = 'ADMINISTRATOR',
  Anonymous = 'ANONYMOUS',
  Client = 'CLIENT',
  Partner = 'PARTNER',
  System = 'SYSTEM',
  User = 'USER'
}

/** Autogenerated input type of AddAccountMember */
export type AddAccountMemberInput = {
  attributes: AddMemberAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of AddAccountMember. */
export type AddAccountMemberPayload = {
  __typename?: 'AddAccountMemberPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  member?: Maybe<Account>;
};

export type AddMemberAttributes = {
  departmentMemberships: Array<DepartmentMembershipAttributes>;
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  role: TeamRoleEnum;
};

export type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  county?: Maybe<Scalars['String']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  line1?: Maybe<Scalars['String']['output']>;
  line2?: Maybe<Scalars['String']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
};

export type AddressAttributes = {
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  county?: InputMaybe<Scalars['String']['input']>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  line1: Scalars['String']['input'];
  line2?: InputMaybe<Scalars['String']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  postalCode: Scalars['String']['input'];
};

/** Autogenerated input type of ApproveEnrollment */
export type ApproveEnrollmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of ApproveEnrollment. */
export type ApproveEnrollmentPayload = {
  __typename?: 'ApproveEnrollmentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  enrollment?: Maybe<Enrollment>;
  errors: Array<Error>;
};

/** Autogenerated input type of ApproveJobApplication */
export type ApproveJobApplicationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of ApproveJobApplication. */
export type ApproveJobApplicationPayload = {
  __typename?: 'ApproveJobApplicationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  jobApplication?: Maybe<JobApplication>;
};

/** Autogenerated input type of ArchiveCheckLists */
export type ArchiveCheckListsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CheckListsFilter>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Autogenerated return type of ArchiveCheckLists. */
export type ArchiveCheckListsPayload = {
  __typename?: 'ArchiveCheckListsPayload';
  checkLists?: Maybe<Array<CheckList>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated input type of ArchiveClient */
export type ArchiveClientInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of ArchiveClient. */
export type ArchiveClientPayload = {
  __typename?: 'ArchiveClientPayload';
  client: Client;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of ArchiveDocuments */
export type ArchiveDocumentsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<DocumentsFilter>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Autogenerated return type of ArchiveDocuments. */
export type ArchiveDocumentsPayload = {
  __typename?: 'ArchiveDocumentsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  documents?: Maybe<Array<Document>>;
  errors: Array<Error>;
};

/** Autogenerated input type of ArchiveFreelanceProfile */
export type ArchiveFreelanceProfileInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of ArchiveFreelanceProfile. */
export type ArchiveFreelanceProfilePayload = {
  __typename?: 'ArchiveFreelanceProfilePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  freelanceProfile: FreelanceProfile;
};

/** Autogenerated input type of ArchivePeople */
export type ArchivePeopleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PeopleFilter>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Autogenerated return type of ArchivePeople. */
export type ArchivePeoplePayload = {
  __typename?: 'ArchivePeoplePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  people?: Maybe<Array<Person>>;
};

export type Attachment = {
  __typename?: 'Attachment';
  contentType?: Maybe<Scalars['String']['output']>;
  downloadPath: Scalars['String']['output'];
  filename?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  thumbPath?: Maybe<Scalars['String']['output']>;
};

export type BankAccount = Node & {
  __typename?: 'BankAccount';
  accountNumber: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  currency: CurrencyEnum;
  default: Scalars['Boolean']['output'];
  deletedAt?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  swiftCode?: Maybe<Scalars['String']['output']>;
};

export type BankAccountAttributes = {
  accountNumber: Scalars['String']['input'];
  currency: CurrencyEnum;
  default?: InputMaybe<Scalars['Boolean']['input']>;
  swiftCode?: InputMaybe<Scalars['String']['input']>;
};

export type Base64AttachmentAttributes = {
  data: Scalars['String']['input'];
  filename?: InputMaybe<Scalars['String']['input']>;
};

export type BenefitInvoiceLine = InvoiceLine & Node & {
  __typename?: 'BenefitInvoiceLine';
  amount: Scalars['Int']['output'];
  collapsed: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  invoice: Invoice;
  lineType: LineTypeEnum;
  occupationCode: OccupationCode;
  quantity: Scalars['Float']['output'];
  remainingQuantity: Scalars['Float']['output'];
  unitPrice: Scalars['Int']['output'];
  vat: Scalars['Int']['output'];
  vatRate: Scalars['Float']['output'];
};

export type BulkInvoiceLineAttributes = {
  addressFrom?: InputMaybe<Scalars['String']['input']>;
  addressTo?: InputMaybe<Scalars['String']['input']>;
  collapsed?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  dietNonTaxableUnitPrice?: InputMaybe<Scalars['Int']['input']>;
  dietTaxableUnitPrice?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  lineType?: InputMaybe<LineTypeEnum>;
  occupationCodeId?: InputMaybe<Scalars['ID']['input']>;
  quantity?: InputMaybe<Scalars['Float']['input']>;
  unitPrice?: InputMaybe<Scalars['Int']['input']>;
  vatRate?: InputMaybe<Scalars['Float']['input']>;
  workInvoiceLineId?: InputMaybe<Scalars['ID']['input']>;
  workReport?: InputMaybe<WorkReportAttributes>;
};

/** Autogenerated input type of CancelAccountDeletion */
export type CancelAccountDeletionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CancelAccountDeletion. */
export type CancelAccountDeletionPayload = {
  __typename?: 'CancelAccountDeletionPayload';
  account?: Maybe<Account>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated input type of CancelCheckLists */
export type CancelCheckListsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CheckListsFilter>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Autogenerated return type of CancelCheckLists. */
export type CancelCheckListsPayload = {
  __typename?: 'CancelCheckListsPayload';
  checkLists?: Maybe<Array<CheckList>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

export type CancelDocumentAttributes = {
  message?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of CancelDocuments */
export type CancelDocumentsInput = {
  attributes: CancelDocumentAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<DocumentsFilter>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Autogenerated return type of CancelDocuments. */
export type CancelDocumentsPayload = {
  __typename?: 'CancelDocumentsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  documents?: Maybe<Array<Document>>;
  errors: Array<Error>;
};

/** Autogenerated input type of CancelSubscription */
export type CancelSubscriptionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CancelSubscription. */
export type CancelSubscriptionPayload = {
  __typename?: 'CancelSubscriptionPayload';
  account?: Maybe<Account>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

export type Check = Node & {
  __typename?: 'Check';
  checkList: CheckList;
  events?: Maybe<Array<Event>>;
  fields: CheckFields;
  files: Array<Attachment>;
  id: Scalars['ID']['output'];
  refuseReason?: Maybe<Scalars['String']['output']>;
  status: CheckStatusEnum;
  type: CheckTypeEnum;
};

export type CheckAttributes = {
  fields?: InputMaybe<CheckFieldsAttributes>;
  files?: InputMaybe<Array<Base64AttachmentAttributes>>;
  identityId?: InputMaybe<Scalars['ID']['input']>;
};

export type CheckFields = {
  __typename?: 'CheckFields';
  accountNumber?: Maybe<Scalars['String']['output']>;
  expiryDate?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  identifier?: Maybe<Scalars['String']['output']>;
  issueDate?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  nationality?: Maybe<Scalars['String']['output']>;
  obtainedAt?: Maybe<Scalars['String']['output']>;
  passportNumber?: Maybe<Scalars['String']['output']>;
};

export type CheckFieldsAttributes = {
  accountNumber?: InputMaybe<Scalars['String']['input']>;
  expirationDate?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  issueDate?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
};

export enum CheckFileDocumentTypeEnum {
  CrcAlreadyIssued = 'CRC_ALREADY_ISSUED',
  ImageNotQualified = 'IMAGE_NOT_QUALIFIED',
  InformationRequired = 'INFORMATION_REQUIRED',
  NotCrc = 'NOT_CRC',
  ProbablyCrc = 'PROBABLY_CRC',
  PurposeLetter = 'PURPOSE_LETTER',
  UnprocessableFile = 'UNPROCESSABLE_FILE',
  ValidCrc = 'VALID_CRC'
}

export type CheckList = Node & {
  __typename?: 'CheckList';
  alreadyEmployed: Scalars['Boolean']['output'];
  archivedAt?: Maybe<Scalars['String']['output']>;
  checkRequest: CheckRequest;
  checks: Array<Check>;
  department?: Maybe<Department>;
  departments: Array<Department>;
  email: Scalars['EmailField']['output'];
  employedOn?: Maybe<Scalars['String']['output']>;
  events?: Maybe<Array<CheckListEvent>>;
  id: Scalars['ID']['output'];
  jobDescription?: Maybe<Scalars['String']['output']>;
  jobRole?: Maybe<Scalars['String']['output']>;
  lastContactedAt?: Maybe<Scalars['String']['output']>;
  name: Name;
  phone?: Maybe<Scalars['String']['output']>;
  purposeLetterPath?: Maybe<Scalars['String']['output']>;
  recentlyDoneThisCheck: Scalars['Boolean']['output'];
  refuseReason?: Maybe<Scalars['String']['output']>;
  requestedAt: Scalars['String']['output'];
  status: CheckStatusEnum;
  temporaryJob: Scalars['Boolean']['output'];
};

export type CheckListEvent = {
  __typename?: 'CheckListEvent';
  actor: ActorEnum;
  actorName?: Maybe<Scalars['String']['output']>;
  eventType: CheckListEventTypeEnum;
  note?: Maybe<Scalars['String']['output']>;
  occurredAt: Scalars['String']['output'];
  status: CheckStatusEnum;
};

export enum CheckListEventTypeEnum {
  CheckCleared = 'CHECK_CLEARED',
  CheckConsented = 'CHECK_CONSENTED',
  CheckFileAnalysesRefuted = 'CHECK_FILE_ANALYSES_REFUTED',
  CheckFileAnalysesSucceeded = 'CHECK_FILE_ANALYSES_SUCCEEDED',
  CheckInReview = 'CHECK_IN_REVIEW',
  CheckListAutoReminderSent = 'CHECK_LIST_AUTO_REMINDER_SENT',
  CheckListCancelled = 'CHECK_LIST_CANCELLED',
  CheckListCleared = 'CHECK_LIST_CLEARED',
  CheckListConsented = 'CHECK_LIST_CONSENTED',
  CheckListFinalReminderSent = 'CHECK_LIST_FINAL_REMINDER_SENT',
  CheckListInReview = 'CHECK_LIST_IN_REVIEW',
  CheckListPurposeLetterGenerated = 'CHECK_LIST_PURPOSE_LETTER_GENERATED',
  CheckListRefused = 'CHECK_LIST_REFUSED',
  CheckListRejected = 'CHECK_LIST_REJECTED',
  CheckListReminderSent = 'CHECK_LIST_REMINDER_SENT',
  CheckListRequested = 'CHECK_LIST_REQUESTED',
  CheckOverridden = 'CHECK_OVERRIDDEN',
  CheckRefused = 'CHECK_REFUSED',
  CheckRejected = 'CHECK_REJECTED'
}

export type CheckListPagination = {
  __typename?: 'CheckListPagination';
  exportPath?: Maybe<Scalars['String']['output']>;
  items: Array<CheckList>;
  total: Scalars['Int']['output'];
};

export type CheckListsFilter = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  departmentId?: InputMaybe<Scalars['ID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<CheckStatusEnum>;
};

export enum CheckListsSortByEnum {
  LastContactedAtAsc = 'LAST_CONTACTED_AT_ASC',
  LastContactedAtDesc = 'LAST_CONTACTED_AT_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC'
}

export type CheckRequest = Node & {
  __typename?: 'CheckRequest';
  account: Account;
  checkLists: Array<CheckList>;
  checkTypes: Array<CheckTypeEnum>;
  contactEmail?: Maybe<Scalars['String']['output']>;
  contactName?: Maybe<Scalars['String']['output']>;
  contactPhoneNumber?: Maybe<Scalars['String']['output']>;
  contactRole?: Maybe<Scalars['String']['output']>;
  countries?: Maybe<Array<IdentityCountryCodeEnum>>;
  crcPurpose?: Maybe<CrcPurpose>;
  eLearningCourse: ELearningCourseEnum;
  files: Array<Attachment>;
  id: Scalars['ID']['output'];
  includeCheckedCandidates?: Maybe<Scalars['Boolean']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  organizationName?: Maybe<Scalars['String']['output']>;
  organizationNumber?: Maybe<Scalars['String']['output']>;
  previewPurposeLetterPath?: Maybe<Scalars['String']['output']>;
  status: CheckRequestStatusEnum;
  type: CheckTypeEnum;
};

export type CheckRequestAttributes = {
  contactEmail?: InputMaybe<Scalars['String']['input']>;
  contactName?: InputMaybe<Scalars['String']['input']>;
  contactPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  contactRole?: InputMaybe<Scalars['String']['input']>;
  countries?: InputMaybe<Array<IdentityCountryCodeEnum>>;
  crcPurposeId?: InputMaybe<Scalars['ID']['input']>;
  eLearningCourse?: InputMaybe<ELearningCourseEnum>;
  includeCheckedCandidates?: InputMaybe<Scalars['Boolean']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  organizationName?: InputMaybe<Scalars['String']['input']>;
  organizationNumber?: InputMaybe<Scalars['String']['input']>;
};

export enum CheckRequestStatusEnum {
  Draft = 'DRAFT',
  Submitted = 'SUBMITTED'
}

export enum CheckStatusEnum {
  Cancelled = 'CANCELLED',
  Cleared = 'CLEARED',
  Consented = 'CONSENTED',
  InReview = 'IN_REVIEW',
  Refused = 'REFUSED',
  Rejected = 'REJECTED',
  Requested = 'REQUESTED'
}

export enum CheckTypeEnum {
  BankAccount = 'BANK_ACCOUNT',
  BankId = 'BANK_ID',
  CriminalRecord = 'CRIMINAL_RECORD',
  DrivingLicense = 'DRIVING_LICENSE',
  ELearning = 'E_LEARNING',
  HealthPersonnelRegister = 'HEALTH_PERSONNEL_REGISTER',
  HseCard = 'HSE_CARD',
  Identity = 'IDENTITY',
  Information = 'INFORMATION',
  Passport = 'PASSPORT',
  RightToWork = 'RIGHT_TO_WORK'
}

/** Invoice receiver */
export type Client = Node & {
  __typename?: 'Client';
  address?: Maybe<Address>;
  archivedAt?: Maybe<Scalars['String']['output']>;
  /** Unique identifier to be used in accounting systems */
  clientNumber: Scalars['Int']['output'];
  clientType: ClientTypeEnum;
  email?: Maybe<Scalars['String']['output']>;
  friendlyName?: Maybe<Scalars['String']['output']>;
  helpArticle?: Maybe<HelpArticle>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  nationalRegisterCheck: Scalars['Boolean']['output'];
  orgNumber?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  preferredDeliveryMethod: ClientPreferredDeliveryMethodEnum;
  referencePeople: Array<ReferencePerson>;
};

export type ClientPagination = {
  __typename?: 'ClientPagination';
  exportPath?: Maybe<Scalars['String']['output']>;
  items: Array<Client>;
  total: Scalars['Int']['output'];
};

export enum ClientPreferredDeliveryMethodEnum {
  Ehf = 'EHF',
  Email = 'EMAIL',
  None = 'NONE'
}

export enum ClientTypeEnum {
  Individual = 'INDIVIDUAL',
  Organization = 'ORGANIZATION'
}

export type ClientsFilter = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  clientType?: InputMaybe<ClientTypeEnum>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export enum ClientsSortByEnum {
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC'
}

/** Autogenerated input type of CloseWbReport */
export type CloseWbReportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of CloseWbReport. */
export type CloseWbReportPayload = {
  __typename?: 'CloseWbReportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  wbReport?: Maybe<WbReport>;
};

export type Company = {
  __typename?: 'Company';
  address?: Maybe<Address>;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  markedAsBankruptcy?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  orgNumber: Scalars['String']['output'];
  underForcedLiquidation?: Maybe<Scalars['Boolean']['output']>;
  underLiquidation?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of ConsentCheck */
export type ConsentCheckInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated input type of ConsentCheckList */
export type ConsentCheckListInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of ConsentCheckList. */
export type ConsentCheckListPayload = {
  __typename?: 'ConsentCheckListPayload';
  checkList?: Maybe<CheckList>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated return type of ConsentCheck. */
export type ConsentCheckPayload = {
  __typename?: 'ConsentCheckPayload';
  check?: Maybe<Check>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

export enum CountryCodeEnum {
  Ad = 'AD',
  Ae = 'AE',
  Af = 'AF',
  Ag = 'AG',
  Ai = 'AI',
  Al = 'AL',
  Am = 'AM',
  Ao = 'AO',
  Aq = 'AQ',
  Ar = 'AR',
  As = 'AS',
  At = 'AT',
  Au = 'AU',
  Aw = 'AW',
  Ax = 'AX',
  Az = 'AZ',
  Ba = 'BA',
  Bb = 'BB',
  Bd = 'BD',
  Be = 'BE',
  Bf = 'BF',
  Bg = 'BG',
  Bh = 'BH',
  Bi = 'BI',
  Bj = 'BJ',
  Bl = 'BL',
  Bm = 'BM',
  Bn = 'BN',
  Bo = 'BO',
  Bq = 'BQ',
  Br = 'BR',
  Bs = 'BS',
  Bt = 'BT',
  Bv = 'BV',
  Bw = 'BW',
  By = 'BY',
  Bz = 'BZ',
  Ca = 'CA',
  Cc = 'CC',
  Cd = 'CD',
  Cf = 'CF',
  Cg = 'CG',
  Ch = 'CH',
  Ci = 'CI',
  Ck = 'CK',
  Cl = 'CL',
  Cm = 'CM',
  Cn = 'CN',
  Co = 'CO',
  Cr = 'CR',
  Cu = 'CU',
  Cv = 'CV',
  Cw = 'CW',
  Cx = 'CX',
  Cy = 'CY',
  Cz = 'CZ',
  De = 'DE',
  Dj = 'DJ',
  Dk = 'DK',
  Dm = 'DM',
  Do = 'DO',
  Dz = 'DZ',
  Ec = 'EC',
  Ee = 'EE',
  Eg = 'EG',
  Eh = 'EH',
  Er = 'ER',
  Es = 'ES',
  Et = 'ET',
  Fi = 'FI',
  Fj = 'FJ',
  Fk = 'FK',
  Fm = 'FM',
  Fo = 'FO',
  Fr = 'FR',
  Ga = 'GA',
  Gb = 'GB',
  Gd = 'GD',
  Ge = 'GE',
  Gf = 'GF',
  Gg = 'GG',
  Gh = 'GH',
  Gi = 'GI',
  Gl = 'GL',
  Gm = 'GM',
  Gn = 'GN',
  Gp = 'GP',
  Gq = 'GQ',
  Gr = 'GR',
  Gs = 'GS',
  Gt = 'GT',
  Gu = 'GU',
  Gw = 'GW',
  Gy = 'GY',
  Hk = 'HK',
  Hm = 'HM',
  Hn = 'HN',
  Hr = 'HR',
  Ht = 'HT',
  Hu = 'HU',
  Id = 'ID',
  Ie = 'IE',
  Il = 'IL',
  Im = 'IM',
  In = 'IN',
  Io = 'IO',
  Iq = 'IQ',
  Ir = 'IR',
  Is = 'IS',
  It = 'IT',
  Je = 'JE',
  Jm = 'JM',
  Jo = 'JO',
  Jp = 'JP',
  Ke = 'KE',
  Kg = 'KG',
  Kh = 'KH',
  Ki = 'KI',
  Km = 'KM',
  Kn = 'KN',
  Kp = 'KP',
  Kr = 'KR',
  Kw = 'KW',
  Ky = 'KY',
  Kz = 'KZ',
  La = 'LA',
  Lb = 'LB',
  Lc = 'LC',
  Li = 'LI',
  Lk = 'LK',
  Lr = 'LR',
  Ls = 'LS',
  Lt = 'LT',
  Lu = 'LU',
  Lv = 'LV',
  Ly = 'LY',
  Ma = 'MA',
  Mc = 'MC',
  Md = 'MD',
  Me = 'ME',
  Mf = 'MF',
  Mg = 'MG',
  Mh = 'MH',
  Mk = 'MK',
  Ml = 'ML',
  Mm = 'MM',
  Mn = 'MN',
  Mo = 'MO',
  Mp = 'MP',
  Mq = 'MQ',
  Mr = 'MR',
  Ms = 'MS',
  Mt = 'MT',
  Mu = 'MU',
  Mv = 'MV',
  Mw = 'MW',
  Mx = 'MX',
  My = 'MY',
  Mz = 'MZ',
  Na = 'NA',
  Nc = 'NC',
  Ne = 'NE',
  Nf = 'NF',
  Ng = 'NG',
  Ni = 'NI',
  Nl = 'NL',
  No = 'NO',
  Np = 'NP',
  Nr = 'NR',
  Nu = 'NU',
  Nz = 'NZ',
  Om = 'OM',
  Pa = 'PA',
  Pe = 'PE',
  Pf = 'PF',
  Pg = 'PG',
  Ph = 'PH',
  Pk = 'PK',
  Pl = 'PL',
  Pm = 'PM',
  Pn = 'PN',
  Pr = 'PR',
  Ps = 'PS',
  Pt = 'PT',
  Pw = 'PW',
  Py = 'PY',
  Qa = 'QA',
  Re = 'RE',
  Ro = 'RO',
  Rs = 'RS',
  Ru = 'RU',
  Rw = 'RW',
  Sa = 'SA',
  Sb = 'SB',
  Sc = 'SC',
  Sd = 'SD',
  Se = 'SE',
  Sg = 'SG',
  Sh = 'SH',
  Si = 'SI',
  Sj = 'SJ',
  Sk = 'SK',
  Sl = 'SL',
  Sm = 'SM',
  Sn = 'SN',
  So = 'SO',
  Sr = 'SR',
  Ss = 'SS',
  St = 'ST',
  Sv = 'SV',
  Sx = 'SX',
  Sy = 'SY',
  Sz = 'SZ',
  Tc = 'TC',
  Td = 'TD',
  Tf = 'TF',
  Tg = 'TG',
  Th = 'TH',
  Tj = 'TJ',
  Tk = 'TK',
  Tl = 'TL',
  Tm = 'TM',
  Tn = 'TN',
  To = 'TO',
  Tr = 'TR',
  Tt = 'TT',
  Tv = 'TV',
  Tw = 'TW',
  Tz = 'TZ',
  Ua = 'UA',
  Ug = 'UG',
  Um = 'UM',
  Us = 'US',
  Uy = 'UY',
  Uz = 'UZ',
  Va = 'VA',
  Vc = 'VC',
  Ve = 'VE',
  Vg = 'VG',
  Vi = 'VI',
  Vn = 'VN',
  Vu = 'VU',
  Wf = 'WF',
  Ws = 'WS',
  Ye = 'YE',
  Yt = 'YT',
  Za = 'ZA',
  Zm = 'ZM',
  Zw = 'ZW'
}

export type Counts = {
  __typename?: 'Counts';
  archivedAllDocuments: Scalars['Int']['output'];
  archivedCancelledDocuments: Scalars['Int']['output'];
  archivedDraftDocuments: Scalars['Int']['output'];
  archivedPendingDocuments: Scalars['Int']['output'];
  archivedRefusedDocuments: Scalars['Int']['output'];
  archivedSignedDocuments: Scalars['Int']['output'];
  checkLists: Scalars['Int']['output'];
  departments: Scalars['Int']['output'];
  members: Scalars['Int']['output'];
  people: Scalars['Int']['output'];
  receivedAllDocuments: Scalars['Int']['output'];
  receivedCancelledDocuments: Scalars['Int']['output'];
  receivedDraftDocuments: Scalars['Int']['output'];
  receivedPendingDocuments: Scalars['Int']['output'];
  receivedRefusedDocuments: Scalars['Int']['output'];
  receivedSignedDocuments: Scalars['Int']['output'];
  sentAllDocuments: Scalars['Int']['output'];
  sentCancelledDocuments: Scalars['Int']['output'];
  sentDraftDocuments: Scalars['Int']['output'];
  sentPendingDocuments: Scalars['Int']['output'];
  sentRefusedDocuments: Scalars['Int']['output'];
  sentSignedDocuments: Scalars['Int']['output'];
  wbReports: Scalars['Int']['output'];
};

export type CrcPurpose = Node & {
  __typename?: 'CrcPurpose';
  category: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  legalBasis: Scalars['String']['output'];
  purpose: Scalars['String']['output'];
  tags: Array<AccountTagEnum>;
};

export type CrcPurposePagination = {
  __typename?: 'CrcPurposePagination';
  exportPath?: Maybe<Scalars['String']['output']>;
  items: Array<CrcPurpose>;
  total: Scalars['Int']['output'];
};

export type CreateAccountAttributes = {
  name: Scalars['String']['input'];
};

/** Autogenerated input type of CreateBankAccount */
export type CreateBankAccountInput = {
  attributes: BankAccountAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  freelanceProfileId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateBankAccount. */
export type CreateBankAccountPayload = {
  __typename?: 'CreateBankAccountPayload';
  bankAccount?: Maybe<BankAccount>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

export type CreateCheckListAttributes = {
  alreadyEmployed?: InputMaybe<Scalars['Boolean']['input']>;
  departmentId: Scalars['ID']['input'];
  email: Scalars['EmailField']['input'];
  employedOn?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  jobDescription?: InputMaybe<Scalars['String']['input']>;
  jobRole?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  temporaryJob?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated input type of CreateCheckLists */
export type CreateCheckListsInput = {
  attributes: Array<CreateCheckListAttributes>;
  checkRequestId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateCheckLists. */
export type CreateCheckListsPayload = {
  __typename?: 'CreateCheckListsPayload';
  checkRequest?: Maybe<CheckRequest>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated input type of CreateCheckRequest */
export type CreateCheckRequestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  type: CheckTypeEnum;
};

/** Autogenerated return type of CreateCheckRequest. */
export type CreateCheckRequestPayload = {
  __typename?: 'CreateCheckRequestPayload';
  checkRequest?: Maybe<CheckRequest>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

export type CreateClientAttributes = {
  address: AddressAttributes;
  clientNumber?: InputMaybe<Scalars['Int']['input']>;
  clientNumberSeries?: InputMaybe<RangeAttributes>;
  clientType: ClientTypeEnum;
  email?: InputMaybe<Scalars['String']['input']>;
  freelanceProfileId: Scalars['ID']['input'];
  friendlyName?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nationalRegisterCheck?: InputMaybe<Scalars['Boolean']['input']>;
  orgNumber?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  preferredDeliveryMethod?: InputMaybe<ClientPreferredDeliveryMethodEnum>;
  referencePeople?: InputMaybe<Array<ReferencePersonAttributes>>;
};

/** Autogenerated input type of CreateClient */
export type CreateClientInput = {
  attributes: CreateClientAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateClient. */
export type CreateClientPayload = {
  __typename?: 'CreateClientPayload';
  client?: Maybe<Client>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated input type of CreateCreditNote */
export type CreateCreditNoteInput = {
  attributes: CreditNoteAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateCreditNote. */
export type CreateCreditNotePayload = {
  __typename?: 'CreateCreditNotePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  creditNote?: Maybe<CreditNote>;
  errors: Array<Error>;
};

/** Autogenerated input type of CreateDepartment */
export type CreateDepartmentInput = {
  attributes: DepartmentAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateDepartment. */
export type CreateDepartmentPayload = {
  __typename?: 'CreateDepartmentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  department?: Maybe<Department>;
  errors: Array<Error>;
};

/** Autogenerated input type of CreateDocumentFile */
export type CreateDocumentFileInput = {
  attributes: DocumentFileAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  documentId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateDocumentFile. */
export type CreateDocumentFilePayload = {
  __typename?: 'CreateDocumentFilePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  documentFile?: Maybe<DocumentFile>;
  errors: Array<Error>;
};

/** Autogenerated input type of CreateDocument */
export type CreateDocumentInput = {
  attributes: DocumentAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateDocument. */
export type CreateDocumentPayload = {
  __typename?: 'CreateDocumentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  document?: Maybe<Document>;
  errors: Array<Error>;
};

export type CreateFreelanceProfileAttributes = {
  address: AddressAttributes;
  bankAccount: BankAccountAttributes;
  country?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  freelanceType: FreelanceTypeEnum;
  invoiceLogo?: InputMaybe<Base64AttachmentAttributes>;
  invoiceStartingNumber?: InputMaybe<Scalars['Int']['input']>;
  journalFormat?: InputMaybe<JournalFormatEnum>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  organizationName?: InputMaybe<Scalars['String']['input']>;
  organizationNumber?: InputMaybe<Scalars['String']['input']>;
  personalNumber?: InputMaybe<Scalars['String']['input']>;
  referencePeople?: InputMaybe<Array<ReferencePersonAttributes>>;
};

/** Autogenerated input type of CreateFreelanceProfile */
export type CreateFreelanceProfileInput = {
  attributes: CreateFreelanceProfileAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateFreelanceProfile. */
export type CreateFreelanceProfilePayload = {
  __typename?: 'CreateFreelanceProfilePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  freelanceProfile?: Maybe<FreelanceProfile>;
};

export type CreateInvoiceAttributes = {
  bankAccountId?: InputMaybe<Scalars['ID']['input']>;
  buyerReference?: InputMaybe<Scalars['String']['input']>;
  clientId: Scalars['ID']['input'];
  clientReferencePerson?: InputMaybe<ReferencePersonAttributes>;
  dueOn?: InputMaybe<Scalars['String']['input']>;
  invoicedOn?: InputMaybe<Scalars['String']['input']>;
  lines?: InputMaybe<Array<BulkInvoiceLineAttributes>>;
  orderReference?: InputMaybe<Scalars['String']['input']>;
  senderReferencePerson?: InputMaybe<ReferencePersonAttributes>;
};

/** Autogenerated input type of CreateInvoice */
export type CreateInvoiceInput = {
  attributes: CreateInvoiceAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateInvoiceLineAttributes = {
  addressFrom?: InputMaybe<Scalars['String']['input']>;
  addressTo?: InputMaybe<Scalars['String']['input']>;
  collapsed?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  dietNonTaxableUnitPrice?: InputMaybe<Scalars['Int']['input']>;
  dietTaxableUnitPrice?: InputMaybe<Scalars['Int']['input']>;
  lineType: LineTypeEnum;
  occupationCodeId: Scalars['ID']['input'];
  quantity?: InputMaybe<Scalars['Float']['input']>;
  unitPrice?: InputMaybe<Scalars['Int']['input']>;
  vatRate?: InputMaybe<Scalars['Float']['input']>;
  workInvoiceLineId?: InputMaybe<Scalars['ID']['input']>;
  workReport?: InputMaybe<WorkReportAttributes>;
};

/** Autogenerated input type of CreateInvoiceLine */
export type CreateInvoiceLineInput = {
  attributes: CreateInvoiceLineAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  invoiceId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateInvoiceLine. */
export type CreateInvoiceLinePayload = {
  __typename?: 'CreateInvoiceLinePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  invoiceLine?: Maybe<InvoiceLine>;
};

/** Autogenerated return type of CreateInvoice. */
export type CreateInvoicePayload = {
  __typename?: 'CreateInvoicePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  invoice?: Maybe<Invoice>;
};

export type CreateJobApplicationAttributes = {
  applicationFormAnswers?: InputMaybe<Array<FormAnswerAttributes>>;
  jobId: Scalars['ID']['input'];
};

/** Autogenerated input type of CreateJobApplication */
export type CreateJobApplicationInput = {
  attributes: CreateJobApplicationAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateJobApplication. */
export type CreateJobApplicationPayload = {
  __typename?: 'CreateJobApplicationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  jobApplication?: Maybe<JobApplication>;
};

export type CreateJobAttributes = {
  address?: InputMaybe<AddressAttributes>;
  applicationDeadline?: InputMaybe<Scalars['String']['input']>;
  applicationForm?: InputMaybe<FormAttributes>;
  applicationLimit?: InputMaybe<Scalars['Int']['input']>;
  colors?: InputMaybe<PartnerColorsAttributes>;
  compensation?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  enrollmentCallback?: InputMaybe<Scalars['Boolean']['input']>;
  jobChecks?: InputMaybe<Array<JobCheckConfigAttributes>>;
  jobType?: InputMaybe<Scalars['String']['input']>;
  languageRequirements?: InputMaybe<Scalars['String']['input']>;
  logo?: InputMaybe<Base64AttachmentAttributes>;
  payout?: InputMaybe<Scalars['String']['input']>;
  privacyLink?: InputMaybe<Scalars['String']['input']>;
  tagline?: InputMaybe<Scalars['String']['input']>;
  termsLink?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

/** Autogenerated input type of CreateJobCheck */
export type CreateJobCheckInput = {
  attributes: JobCheckAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateJobCheck. */
export type CreateJobCheckPayload = {
  __typename?: 'CreateJobCheckPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  jobCheck?: Maybe<JobCheck>;
};

/** Autogenerated input type of CreateJob */
export type CreateJobInput = {
  attributes: CreateJobAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateJob. */
export type CreateJobPayload = {
  __typename?: 'CreateJobPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  job?: Maybe<Job>;
};

/** Autogenerated input type of CreateJournal */
export type CreateJournalInput = {
  attributes: JournalAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateJournal. */
export type CreateJournalPayload = {
  __typename?: 'CreateJournalPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type CreatePaymentMethodAttributes = {
  address: AddressAttributes;
  contactName: Scalars['String']['input'];
  email: Scalars['String']['input'];
  orderReference: Scalars['String']['input'];
  organizationNumber: Scalars['String']['input'];
};

/** Autogenerated input type of CreatePaymentMethod */
export type CreatePaymentMethodInput = {
  attributes: CreatePaymentMethodAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreatePaymentMethod. */
export type CreatePaymentMethodPayload = {
  __typename?: 'CreatePaymentMethodPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  paymentMethod?: Maybe<PaymentMethod>;
};

/** Autogenerated input type of CreatePolicyDocument */
export type CreatePolicyDocumentInput = {
  attributes: Base64AttachmentAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreatePolicyDocument. */
export type CreatePolicyDocumentPayload = {
  __typename?: 'CreatePolicyDocumentPayload';
  account?: Maybe<Account>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated input type of CreateReceipt */
export type CreateReceiptInput = {
  attributes: ReceiptAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  invoiceId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateReceipt. */
export type CreateReceiptPayload = {
  __typename?: 'CreateReceiptPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  receipt?: Maybe<Receipt>;
};

/** Autogenerated input type of CreateRoutineGuideline */
export type CreateRoutineGuidelineInput = {
  attributes: Base64AttachmentAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateRoutineGuideline. */
export type CreateRoutineGuidelinePayload = {
  __typename?: 'CreateRoutineGuidelinePayload';
  account?: Maybe<Account>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated input type of CreateSession */
export type CreateSessionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['EmailField']['input'];
  password: Scalars['String']['input'];
};

/** Autogenerated input type of CreateSessionOtp */
export type CreateSessionOtpInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['EmailField']['input'];
};

/** Autogenerated return type of CreateSessionOtp. */
export type CreateSessionOtpPayload = {
  __typename?: 'CreateSessionOtpPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated return type of CreateSession. */
export type CreateSessionPayload = {
  __typename?: 'CreateSessionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  token?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of CreateSignatures */
export type CreateSignaturesInput = {
  attributes: Array<SignatureAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  documentId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateSignatures. */
export type CreateSignaturesPayload = {
  __typename?: 'CreateSignaturesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  document?: Maybe<Document>;
  errors: Array<Error>;
};

/** Autogenerated input type of CreateStripeCard */
export type CreateStripeCardInput = {
  attributes: StripeCardAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateStripeCard. */
export type CreateStripeCardPayload = {
  __typename?: 'CreateStripeCardPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  paymentMethod?: Maybe<PaymentMethod>;
};

/** Autogenerated input type of CreateStripeSetupIntent */
export type CreateStripeSetupIntentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateStripeSetupIntent. */
export type CreateStripeSetupIntentPayload = {
  __typename?: 'CreateStripeSetupIntentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  setupIntent?: Maybe<StripeSetupIntent>;
};

/** Autogenerated input type of CreateTeamAccount */
export type CreateTeamAccountInput = {
  attributes: CreateAccountAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateTeamAccount. */
export type CreateTeamAccountPayload = {
  __typename?: 'CreateTeamAccountPayload';
  account?: Maybe<Account>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

export type CreateTransferAttributes = {
  amount: Scalars['Int']['input'];
  currency?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  fromFinanceAccount?: InputMaybe<Scalars['String']['input']>;
  invoiceId: Scalars['ID']['input'];
  toFinanceAccount?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of CreateTransfer */
export type CreateTransferInput = {
  attributes: CreateTransferAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateTransfer. */
export type CreateTransferPayload = {
  __typename?: 'CreateTransferPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  transfer?: Maybe<Transfer>;
};

/** Autogenerated input type of CreateWbHandler */
export type CreateWbHandlerInput = {
  accountId: Scalars['ID']['input'];
  attributes: WbHandlerAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateWbHandler. */
export type CreateWbHandlerPayload = {
  __typename?: 'CreateWbHandlerPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  wbHandler?: Maybe<WbHandler>;
};

/** Autogenerated input type of CreateWbMessage */
export type CreateWbMessageInput = {
  attributes: WbMessageAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  wbReportId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateWbMessage. */
export type CreateWbMessagePayload = {
  __typename?: 'CreateWbMessagePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  wbReport?: Maybe<WbReport>;
};

/** Autogenerated input type of CreateWbReportFile */
export type CreateWbReportFileInput = {
  attributes: Base64AttachmentAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateWbReportFile. */
export type CreateWbReportFilePayload = {
  __typename?: 'CreateWbReportFilePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  wbReport?: Maybe<PublicWbReport>;
};

/** Autogenerated input type of CreateWbReport */
export type CreateWbReportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  type: WbReportTypeEnum;
  wbSlug: Scalars['String']['input'];
};

/** Autogenerated return type of CreateWbReport. */
export type CreateWbReportPayload = {
  __typename?: 'CreateWbReportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  wbReport?: Maybe<PublicWbReport>;
};

export type CreditNote = Node & {
  __typename?: 'CreditNote';
  amount: Scalars['Int']['output'];
  approvedAt?: Maybe<Scalars['String']['output']>;
  client?: Maybe<Client>;
  creditNoteNumber?: Maybe<Scalars['String']['output']>;
  creditedOn?: Maybe<Scalars['String']['output']>;
  currency: CurrencyEnum;
  ehfSentAt?: Maybe<Scalars['String']['output']>;
  emailSentAt?: Maybe<Scalars['String']['output']>;
  employerTaxAmount?: Maybe<Scalars['Int']['output']>;
  events?: Maybe<Array<CreditNoteEvent>>;
  freelanceProfile: FreelanceProfile;
  grossSalary?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  invoice: Invoice;
  lines: Array<CreditNoteLine>;
  payoutAmount?: Maybe<Scalars['Int']['output']>;
  pdfDocument?: Maybe<Attachment>;
  platformFeeAmount?: Maybe<Scalars['Int']['output']>;
  publishedAt?: Maybe<Scalars['String']['output']>;
  rejectedAt?: Maybe<Scalars['String']['output']>;
  resolvedAt?: Maybe<Scalars['String']['output']>;
  salaryTaxAmount?: Maybe<Scalars['Int']['output']>;
  status: InvoiceStatusEnum;
  vat: Scalars['Int']['output'];
};

export type CreditNoteAttributes = {
  invoiceId: Scalars['ID']['input'];
  lines: Array<CreditNoteLineAttributes>;
};

export type CreditNoteEvent = {
  __typename?: 'CreditNoteEvent';
  actor: ActorEnum;
  eventType: CreditNoteEventTypeEnum;
  occurredAt: Scalars['String']['output'];
  status: InvoiceStatusEnum;
};

export enum CreditNoteEventTypeEnum {
  CreditNoteApproved = 'CREDIT_NOTE_APPROVED',
  CreditNoteApprovedForDeduction = 'CREDIT_NOTE_APPROVED_FOR_DEDUCTION',
  CreditNoteDeducted = 'CREDIT_NOTE_DEDUCTED',
  CreditNotePublished = 'CREDIT_NOTE_PUBLISHED',
  CreditNoteRejected = 'CREDIT_NOTE_REJECTED',
  CreditNoteRejectedForDeduction = 'CREDIT_NOTE_REJECTED_FOR_DEDUCTION',
  CreditNoteResolved = 'CREDIT_NOTE_RESOLVED',
  CreditNoteSentManually = 'CREDIT_NOTE_SENT_MANUALLY',
  CreditNoteSentViaEhf = 'CREDIT_NOTE_SENT_VIA_EHF',
  CreditNoteSentViaEmail = 'CREDIT_NOTE_SENT_VIA_EMAIL',
  CreditNoteSubmitted = 'CREDIT_NOTE_SUBMITTED',
  CreditNoteUnapprovedForDeduction = 'CREDIT_NOTE_UNAPPROVED_FOR_DEDUCTION'
}

export type CreditNoteLine = Node & {
  __typename?: 'CreditNoteLine';
  amount: Scalars['Int']['output'];
  creditNote: CreditNote;
  description?: Maybe<Scalars['String']['output']>;
  employerTaxAmount?: Maybe<Scalars['Int']['output']>;
  grossSalary?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  invoiceLine: InvoiceLine;
  payoutAmount?: Maybe<Scalars['Int']['output']>;
  platformFeeAmount?: Maybe<Scalars['Int']['output']>;
  quantity: Scalars['Float']['output'];
  salaryTaxAmount?: Maybe<Scalars['Int']['output']>;
  vat: Scalars['Int']['output'];
};

export type CreditNoteLineAttributes = {
  description: Scalars['String']['input'];
  invoiceLineId: Scalars['ID']['input'];
  quantity: Scalars['Float']['input'];
};

export type CreditNotePagination = {
  __typename?: 'CreditNotePagination';
  exportPath?: Maybe<Scalars['String']['output']>;
  items: Array<CreditNote>;
  total: Scalars['Int']['output'];
};

export type CreditNotesFilter = {
  amountFrom?: InputMaybe<Scalars['Int']['input']>;
  amountTo?: InputMaybe<Scalars['Int']['input']>;
  clientExclude?: InputMaybe<Array<Scalars['ID']['input']>>;
  clientInclude?: InputMaybe<Array<Scalars['ID']['input']>>;
  creditedOnFrom?: InputMaybe<Scalars['String']['input']>;
  creditedOnTo?: InputMaybe<Scalars['String']['input']>;
  freelanceProfileId?: InputMaybe<Scalars['ID']['input']>;
  publishedAtFrom?: InputMaybe<Scalars['String']['input']>;
  publishedAtTo?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  statusExclude?: InputMaybe<Array<InvoiceStatusEnum>>;
  statusInclude?: InputMaybe<Array<InvoiceStatusEnum>>;
};

export enum CreditNotesSortByEnum {
  AmountAsc = 'AMOUNT_ASC',
  AmountDesc = 'AMOUNT_DESC',
  ClientNameAsc = 'CLIENT_NAME_ASC',
  ClientNameDesc = 'CLIENT_NAME_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreditNoteNumberNumberAsc = 'CREDIT_NOTE_NUMBER_NUMBER_ASC',
  CreditNoteNumberNumberDesc = 'CREDIT_NOTE_NUMBER_NUMBER_DESC',
  PublishedAtAsc = 'PUBLISHED_AT_ASC',
  PublishedAtDesc = 'PUBLISHED_AT_DESC'
}

/** Autogenerated input type of CriiptoVerify */
export type CriiptoVerifyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
  redirectUri: Scalars['String']['input'];
};

/** Autogenerated return type of CriiptoVerify. */
export type CriiptoVerifyPayload = {
  __typename?: 'CriiptoVerifyPayload';
  account?: Maybe<Account>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

export enum CurrencyEnum {
  Chf = 'CHF',
  Czk = 'CZK',
  Dkk = 'DKK',
  Eur = 'EUR',
  Gbp = 'GBP',
  Isk = 'ISK',
  Nok = 'NOK',
  Pln = 'PLN',
  Sek = 'SEK',
  Uah = 'UAH',
  Usd = 'USD'
}

/** Autogenerated input type of DeleteAccount */
export type DeleteAccountInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of DeleteAccount. */
export type DeleteAccountPayload = {
  __typename?: 'DeleteAccountPayload';
  account?: Maybe<Account>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated input type of DeleteIdentity */
export type DeleteIdentityInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteIdentity. */
export type DeleteIdentityPayload = {
  __typename?: 'DeleteIdentityPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  identity?: Maybe<Identity>;
};

/** Autogenerated input type of DeleteWbReport */
export type DeleteWbReportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteWbReport. */
export type DeleteWbReportPayload = {
  __typename?: 'DeleteWbReportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  wbReport?: Maybe<WbReport>;
};

export type Department = Node & {
  __typename?: 'Department';
  canAccess: Scalars['Boolean']['output'];
  canManage: Scalars['Boolean']['output'];
  default: Scalars['Boolean']['output'];
  departmentMemberships: Array<DepartmentMembership>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type DepartmentAttributes = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type DepartmentMembership = Node & {
  __typename?: 'DepartmentMembership';
  account: Account;
  criminalRecordAccess: Scalars['Boolean']['output'];
  department: Department;
  id: Scalars['ID']['output'];
  identityAccess: Scalars['Boolean']['output'];
  role: DepartmentRoleEnum;
};

export type DepartmentMembershipAttributes = {
  criminalRecordAccess?: InputMaybe<Scalars['Boolean']['input']>;
  departmentId: Scalars['ID']['input'];
  identityAccess?: InputMaybe<Scalars['Boolean']['input']>;
  role?: InputMaybe<DepartmentRoleEnum>;
};

export type DepartmentPagination = {
  __typename?: 'DepartmentPagination';
  exportPath?: Maybe<Scalars['String']['output']>;
  items: Array<Department>;
  total: Scalars['Int']['output'];
};

export enum DepartmentRoleEnum {
  Manager = 'MANAGER',
  Member = 'MEMBER'
}

export type DepartmentsFilter = {
  search?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of DestroyAccountLogo */
export type DestroyAccountLogoInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of DestroyAccountLogo. */
export type DestroyAccountLogoPayload = {
  __typename?: 'DestroyAccountLogoPayload';
  account: Account;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of DestroyAccountSymbol */
export type DestroyAccountSymbolInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  personal?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated return type of DestroyAccountSymbol. */
export type DestroyAccountSymbolPayload = {
  __typename?: 'DestroyAccountSymbolPayload';
  account?: Maybe<Account>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of DestroyBankAccount */
export type DestroyBankAccountInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DestroyBankAccount. */
export type DestroyBankAccountPayload = {
  __typename?: 'DestroyBankAccountPayload';
  bankAccount?: Maybe<BankAccount>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated input type of DestroyCheckList */
export type DestroyCheckListInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DestroyCheckList. */
export type DestroyCheckListPayload = {
  __typename?: 'DestroyCheckListPayload';
  checkList?: Maybe<CheckList>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated input type of DestroyCheckRequestFile */
export type DestroyCheckRequestFileInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  fileId: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DestroyCheckRequestFile. */
export type DestroyCheckRequestFilePayload = {
  __typename?: 'DestroyCheckRequestFilePayload';
  checkRequest?: Maybe<CheckRequest>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated input type of DestroyDepartment */
export type DestroyDepartmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DestroyDepartment. */
export type DestroyDepartmentPayload = {
  __typename?: 'DestroyDepartmentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  department?: Maybe<Department>;
  errors: Array<Error>;
};

/** Autogenerated input type of DestroyDocumentFile */
export type DestroyDocumentFileInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DestroyDocumentFile. */
export type DestroyDocumentFilePayload = {
  __typename?: 'DestroyDocumentFilePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  documentType?: Maybe<DocumentFile>;
  errors: Array<Error>;
};

/** Autogenerated input type of DestroyDocuments */
export type DestroyDocumentsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<DocumentsFilter>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Autogenerated return type of DestroyDocuments. */
export type DestroyDocumentsPayload = {
  __typename?: 'DestroyDocumentsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  documents?: Maybe<Array<Document>>;
  errors: Array<Error>;
};

/** Autogenerated input type of DestroyInvoice */
export type DestroyInvoiceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated input type of DestroyInvoiceLine */
export type DestroyInvoiceLineInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DestroyInvoiceLine. */
export type DestroyInvoiceLinePayload = {
  __typename?: 'DestroyInvoiceLinePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated return type of DestroyInvoice. */
export type DestroyInvoicePayload = {
  __typename?: 'DestroyInvoicePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated input type of DestroyPaymentMethod */
export type DestroyPaymentMethodInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DestroyPaymentMethod. */
export type DestroyPaymentMethodPayload = {
  __typename?: 'DestroyPaymentMethodPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  paymentMethod?: Maybe<PaymentMethod>;
};

/** Autogenerated input type of DestroyPeople */
export type DestroyPeopleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PeopleFilter>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Autogenerated return type of DestroyPeople. */
export type DestroyPeoplePayload = {
  __typename?: 'DestroyPeoplePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  people?: Maybe<Array<Person>>;
};

/** Autogenerated input type of DestroyPolicyDocument */
export type DestroyPolicyDocumentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DestroyPolicyDocument. */
export type DestroyPolicyDocumentPayload = {
  __typename?: 'DestroyPolicyDocumentPayload';
  account?: Maybe<Account>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated input type of DestroyReceipt */
export type DestroyReceiptInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DestroyReceipt. */
export type DestroyReceiptPayload = {
  __typename?: 'DestroyReceiptPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated input type of DestroyRoutineGuideline */
export type DestroyRoutineGuidelineInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DestroyRoutineGuideline. */
export type DestroyRoutineGuidelinePayload = {
  __typename?: 'DestroyRoutineGuidelinePayload';
  account?: Maybe<Account>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated input type of DestroySignature */
export type DestroySignatureInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DestroySignature. */
export type DestroySignaturePayload = {
  __typename?: 'DestroySignaturePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  signature?: Maybe<Signature>;
};

/** Autogenerated input type of DestroyTransfer */
export type DestroyTransferInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DestroyTransfer. */
export type DestroyTransferPayload = {
  __typename?: 'DestroyTransferPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated input type of DestroyWbHandler */
export type DestroyWbHandlerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DestroyWbHandler. */
export type DestroyWbHandlerPayload = {
  __typename?: 'DestroyWbHandlerPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  wbHandler?: Maybe<WbHandler>;
};

/** Autogenerated input type of DestroyWbReportFile */
export type DestroyWbReportFileInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  fileId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DestroyWbReportFile. */
export type DestroyWbReportFilePayload = {
  __typename?: 'DestroyWbReportFilePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  wbReport?: Maybe<PublicWbReport>;
};

export type DietInvoiceLine = InvoiceLine & Node & {
  __typename?: 'DietInvoiceLine';
  amount: Scalars['Int']['output'];
  collapsed: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  dietNonTaxableUnitPrice: Scalars['Int']['output'];
  dietTaxableUnitPrice: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  invoice: Invoice;
  lineType: LineTypeEnum;
  occupationCode: OccupationCode;
  quantity: Scalars['Float']['output'];
  remainingQuantity: Scalars['Float']['output'];
  unitPrice: Scalars['Int']['output'];
  vat: Scalars['Int']['output'];
  vatRate: Scalars['Float']['output'];
};

export type Document = Node & {
  __typename?: 'Document';
  account: Account;
  activatedOn?: Maybe<Scalars['String']['output']>;
  activation?: Maybe<DocumentActivationEnum>;
  archivedAt?: Maybe<Scalars['String']['output']>;
  bulk: Scalars['Boolean']['output'];
  bulkSignatures: Array<Signature>;
  category?: Maybe<Scalars['String']['output']>;
  copyRecipients: Array<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  documentFiles?: Maybe<Array<DocumentFile>>;
  documentNumber: Scalars['String']['output'];
  events?: Maybe<Array<DocumentEvent>>;
  expiration?: Maybe<DocumentExpirationEnum>;
  expirationMonths?: Maybe<Scalars['Int']['output']>;
  expiredOn?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  interactedAt?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  owned: Scalars['Boolean']['output'];
  refusedAt?: Maybe<Scalars['String']['output']>;
  senderAccount?: Maybe<Account>;
  sentAt?: Maybe<Scalars['String']['output']>;
  signatures: Array<Signature>;
  signedAt?: Maybe<Scalars['String']['output']>;
  signedDocument?: Maybe<Attachment>;
  status?: Maybe<DocumentStatusEnum>;
  submittedAt?: Maybe<Scalars['String']['output']>;
  terminatedCopyRecipients: Array<Scalars['String']['output']>;
  terminatedOn?: Maybe<Scalars['String']['output']>;
  terminationReason?: Maybe<Scalars['String']['output']>;
  type: DocumentTypeEnum;
};

export enum DocumentActivationEnum {
  Date = 'DATE',
  Signature = 'SIGNATURE'
}

export type DocumentAttributes = {
  activatedOn?: InputMaybe<Scalars['String']['input']>;
  activation?: InputMaybe<DocumentActivationEnum>;
  bulk?: InputMaybe<Scalars['Boolean']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  copyRecipients?: InputMaybe<Array<Scalars['String']['input']>>;
  expiration?: InputMaybe<DocumentExpirationEnum>;
  expirationMonths?: InputMaybe<Scalars['Int']['input']>;
  expiredOn?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  signatures?: InputMaybe<Array<SignatureAttributes>>;
  type?: InputMaybe<DocumentTypeEnum>;
};

export type DocumentEvent = {
  __typename?: 'DocumentEvent';
  actor: ActorEnum;
  actorName?: Maybe<Scalars['String']['output']>;
  eventType: DocumentEventTypeEnum;
  note?: Maybe<Scalars['String']['output']>;
  occurredAt: Scalars['String']['output'];
  status: DocumentStatusEnum;
};

export enum DocumentEventTypeEnum {
  DocumentCancelled = 'DOCUMENT_CANCELLED',
  DocumentExpired = 'DOCUMENT_EXPIRED',
  DocumentSigned = 'DOCUMENT_SIGNED',
  SignatoryDocumentStatusChanged = 'SIGNATORY_DOCUMENT_STATUS_CHANGED',
  SignatoryDownloadLinkOpened = 'SIGNATORY_DOWNLOAD_LINK_OPENED',
  SignatoryRejected = 'SIGNATORY_REJECTED',
  SignatorySigned = 'SIGNATORY_SIGNED',
  SignatorySignError = 'SIGNATORY_SIGN_ERROR',
  SignatorySignLinkOpened = 'SIGNATORY_SIGN_LINK_OPENED',
  SignatureOrderExpired = 'SIGNATURE_ORDER_EXPIRED'
}

export enum DocumentExpirationEnum {
  Activation = 'ACTIVATION',
  Date = 'DATE',
  None = 'NONE'
}

export type DocumentFile = Node & {
  __typename?: 'DocumentFile';
  file: Attachment;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type DocumentFileAttributes = {
  file: Base64AttachmentAttributes;
  name: Scalars['String']['input'];
};

export type DocumentPagination = {
  __typename?: 'DocumentPagination';
  exportPath?: Maybe<Scalars['String']['output']>;
  items: Array<Document>;
  total: Scalars['Int']['output'];
};

export enum DocumentStatusEnum {
  Cancelled = 'CANCELLED',
  Draft = 'DRAFT',
  Expired = 'EXPIRED',
  Pending = 'PENDING',
  Refused = 'REFUSED',
  Signed = 'SIGNED'
}

export enum DocumentTypeEnum {
  Basic = 'BASIC',
  Criipto = 'CRIIPTO'
}

export type DocumentsFilter = {
  activatedOnFrom?: InputMaybe<Scalars['String']['input']>;
  activatedOnTo?: InputMaybe<Scalars['String']['input']>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  createdAtFrom?: InputMaybe<Scalars['String']['input']>;
  createdAtTo?: InputMaybe<Scalars['String']['input']>;
  expiredOnFrom?: InputMaybe<Scalars['String']['input']>;
  expiredOnTo?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  relation?: InputMaybe<DocumentsRelationEnum>;
  search?: InputMaybe<Scalars['String']['input']>;
  signer?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<DocumentStatusEnum>;
};

export enum DocumentsRelationEnum {
  Received = 'RECEIVED',
  Sent = 'SENT'
}

/** Autogenerated input type of DuplicateInvoice */
export type DuplicateInvoiceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DuplicateInvoice. */
export type DuplicateInvoicePayload = {
  __typename?: 'DuplicateInvoicePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  invoice?: Maybe<Invoice>;
};

export enum ELearningCourseEnum {
  Generic = 'GENERIC',
  None = 'NONE',
  Sports = 'SPORTS'
}

export type Enrollment = Node & {
  __typename?: 'Enrollment';
  account: Account;
  approved: Scalars['Boolean']['output'];
  bankAccounts?: Maybe<BankAccount>;
  freelanceProfile?: Maybe<FreelanceProfile>;
  id: Scalars['ID']['output'];
  locked: Scalars['Boolean']['output'];
  partnerAccount: Account;
};

export enum EntityTypeEnum {
  Individual = 'INDIVIDUAL',
  Organization = 'ORGANIZATION'
}

export type Error = {
  __typename?: 'Error';
  code: ErrorCodeEnum;
  path?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export enum ErrorCodeEnum {
  AccountBlocked = 'ACCOUNT_BLOCKED',
  AccountNotFound = 'ACCOUNT_NOT_FOUND',
  AttemptsLimitExceeded = 'ATTEMPTS_LIMIT_EXCEEDED',
  AuthLevel_2Required = 'AUTH_LEVEL_2_REQUIRED',
  BankIdVerificationRequired = 'BANK_ID_VERIFICATION_REQUIRED',
  Blank = 'BLANK',
  BrregQueryFailed = 'BRREG_QUERY_FAILED',
  ClientEmailMissing = 'CLIENT_EMAIL_MISSING',
  ClientInsolvent = 'CLIENT_INSOLVENT',
  DeliveryFrequencyExceeded = 'DELIVERY_FREQUENCY_EXCEEDED',
  DocumentNotFound = 'DOCUMENT_NOT_FOUND',
  DocumentSigned = 'DOCUMENT_SIGNED',
  FreelanceProfileArchived = 'FREELANCE_PROFILE_ARCHIVED',
  Immutable = 'IMMUTABLE',
  ImportError = 'IMPORT_ERROR',
  Inclusion = 'INCLUSION',
  Invalid = 'INVALID',
  InvalidAction = 'INVALID_ACTION',
  InvalidFile = 'INVALID_FILE',
  InvalidInvoicedOn = 'INVALID_INVOICED_ON',
  InvalidPassword = 'INVALID_PASSWORD',
  InvalidPhoneNumber = 'INVALID_PHONE_NUMBER',
  InvalidToken = 'INVALID_TOKEN',
  InvalidTransition = 'INVALID_TRANSITION',
  InvoiceCalculationFailed = 'INVOICE_CALCULATION_FAILED',
  InvoiceNotCredited = 'INVOICE_NOT_CREDITED',
  InvoiceNotOrganization = 'INVOICE_NOT_ORGANIZATION',
  InvoiceNotPublished = 'INVOICE_NOT_PUBLISHED',
  InvoiceNotSubmitted = 'INVOICE_NOT_SUBMITTED',
  InvoicePublished = 'INVOICE_PUBLISHED',
  InvoiceSubmitted = 'INVOICE_SUBMITTED',
  JobCheckPending = 'JOB_CHECK_PENDING',
  JobCheckSucceeded = 'JOB_CHECK_SUCCEEDED',
  LineMismatch = 'LINE_MISMATCH',
  OccupationCodeDisabled = 'OCCUPATION_CODE_DISABLED',
  OrganizationRoleCheckFailed = 'ORGANIZATION_ROLE_CHECK_FAILED',
  OtpExpired = 'OTP_EXPIRED',
  OtpExpiredAndResent = 'OTP_EXPIRED_AND_RESENT',
  PartnerCallbackError = 'PARTNER_CALLBACK_ERROR',
  PaymentProviderError = 'PAYMENT_PROVIDER_ERROR',
  PersonalAccountOwnsTeams = 'PERSONAL_ACCOUNT_OWNS_TEAMS',
  PersonalNumberMismatch = 'PERSONAL_NUMBER_MISMATCH',
  Present = 'PRESENT',
  SenderEqualsReceiver = 'SENDER_EQUALS_RECEIVER',
  SmsSendingFailed = 'SMS_SENDING_FAILED',
  SubscriptionLimitExceeded = 'SUBSCRIPTION_LIMIT_EXCEEDED',
  Taken = 'TAKEN',
  TokenExpired = 'TOKEN_EXPIRED',
  TooBig = 'TOO_BIG',
  TooBigOrEqual = 'TOO_BIG_OR_EQUAL',
  TooShort = 'TOO_SHORT',
  TooSmall = 'TOO_SMALL',
  TooSmallOrEqual = 'TOO_SMALL_OR_EQUAL',
  TransitionFailed = 'TRANSITION_FAILED',
  Unauthorized = 'UNAUTHORIZED',
  UserAlreadyConfirmed = 'USER_ALREADY_CONFIRMED',
  UserBlocked = 'USER_BLOCKED',
  UserExists = 'USER_EXISTS',
  UserNotConfirmed = 'USER_NOT_CONFIRMED',
  UserNotFound = 'USER_NOT_FOUND',
  ValidationFailed = 'VALIDATION_FAILED',
  VerificationFailed = 'VERIFICATION_FAILED'
}

export type Event = {
  __typename?: 'Event';
  actor: ActorEnum;
  eventType: EventTypeEnum;
  occurredAt: Scalars['String']['output'];
};

export enum EventTypeEnum {
  AccountDestroyed = 'ACCOUNT_DESTROYED',
  AccountMerged = 'ACCOUNT_MERGED',
  AttachmentDownloaded = 'ATTACHMENT_DOWNLOADED',
  AMeldingAccepted = 'A_MELDING_ACCEPTED',
  AMeldingApproved = 'A_MELDING_APPROVED',
  AMeldingPaid = 'A_MELDING_PAID',
  AMeldingRejected = 'A_MELDING_REJECTED',
  AMeldingResolved = 'A_MELDING_RESOLVED',
  AMeldingScheduled = 'A_MELDING_SCHEDULED',
  AMeldingSubmitted = 'A_MELDING_SUBMITTED',
  AMeldingVoided = 'A_MELDING_VOIDED',
  CheckCleared = 'CHECK_CLEARED',
  CheckConsented = 'CHECK_CONSENTED',
  CheckFileAnalysesRefuted = 'CHECK_FILE_ANALYSES_REFUTED',
  CheckFileAnalysesSucceeded = 'CHECK_FILE_ANALYSES_SUCCEEDED',
  CheckInReview = 'CHECK_IN_REVIEW',
  CheckListAutoReminderSent = 'CHECK_LIST_AUTO_REMINDER_SENT',
  CheckListCancelled = 'CHECK_LIST_CANCELLED',
  CheckListCleared = 'CHECK_LIST_CLEARED',
  CheckListConsented = 'CHECK_LIST_CONSENTED',
  CheckListFinalReminderSent = 'CHECK_LIST_FINAL_REMINDER_SENT',
  CheckListInReview = 'CHECK_LIST_IN_REVIEW',
  CheckListPurposeLetterGenerated = 'CHECK_LIST_PURPOSE_LETTER_GENERATED',
  CheckListRefused = 'CHECK_LIST_REFUSED',
  CheckListRejected = 'CHECK_LIST_REJECTED',
  CheckListReminderSent = 'CHECK_LIST_REMINDER_SENT',
  CheckListRequested = 'CHECK_LIST_REQUESTED',
  CheckOverridden = 'CHECK_OVERRIDDEN',
  CheckRefused = 'CHECK_REFUSED',
  CheckRejected = 'CHECK_REJECTED',
  CreditNoteApproved = 'CREDIT_NOTE_APPROVED',
  CreditNoteApprovedForDeduction = 'CREDIT_NOTE_APPROVED_FOR_DEDUCTION',
  CreditNoteDeducted = 'CREDIT_NOTE_DEDUCTED',
  CreditNotePublished = 'CREDIT_NOTE_PUBLISHED',
  CreditNoteRejected = 'CREDIT_NOTE_REJECTED',
  CreditNoteRejectedForDeduction = 'CREDIT_NOTE_REJECTED_FOR_DEDUCTION',
  CreditNoteResolved = 'CREDIT_NOTE_RESOLVED',
  CreditNoteSentManually = 'CREDIT_NOTE_SENT_MANUALLY',
  CreditNoteSentViaEhf = 'CREDIT_NOTE_SENT_VIA_EHF',
  CreditNoteSentViaEmail = 'CREDIT_NOTE_SENT_VIA_EMAIL',
  CreditNoteSubmitted = 'CREDIT_NOTE_SUBMITTED',
  CreditNoteUnapprovedForDeduction = 'CREDIT_NOTE_UNAPPROVED_FOR_DEDUCTION',
  DocumentCancelled = 'DOCUMENT_CANCELLED',
  DocumentExpired = 'DOCUMENT_EXPIRED',
  DocumentSigned = 'DOCUMENT_SIGNED',
  ExportedToFiken = 'EXPORTED_TO_FIKEN',
  FikenRequest = 'FIKEN_REQUEST',
  IdentityCreated = 'IDENTITY_CREATED',
  IdentityDeleted = 'IDENTITY_DELETED',
  IdentityUpdated = 'IDENTITY_UPDATED',
  InvoiceApproved = 'INVOICE_APPROVED',
  InvoiceApprovedForPayout = 'INVOICE_APPROVED_FOR_PAYOUT',
  InvoiceCredited = 'INVOICE_CREDITED',
  InvoiceHidden = 'INVOICE_HIDDEN',
  InvoiceNullified = 'INVOICE_NULLIFIED',
  InvoicePaidOut = 'INVOICE_PAID_OUT',
  InvoicePublished = 'INVOICE_PUBLISHED',
  InvoiceRejected = 'INVOICE_REJECTED',
  InvoiceRejectedForPayout = 'INVOICE_REJECTED_FOR_PAYOUT',
  InvoiceResolved = 'INVOICE_RESOLVED',
  InvoiceSentManually = 'INVOICE_SENT_MANUALLY',
  InvoiceSentViaEhf = 'INVOICE_SENT_VIA_EHF',
  InvoiceSentViaEmail = 'INVOICE_SENT_VIA_EMAIL',
  InvoiceSubmitted = 'INVOICE_SUBMITTED',
  InvoiceUnapproved = 'INVOICE_UNAPPROVED',
  InvoiceUnapprovedForPayout = 'INVOICE_UNAPPROVED_FOR_PAYOUT',
  InvoiceUnpublished = 'INVOICE_UNPUBLISHED',
  InvoiceUnrejected = 'INVOICE_UNREJECTED',
  InvoiceVoided = 'INVOICE_VOIDED',
  JobCheckFailed = 'JOB_CHECK_FAILED',
  JobCheckInReview = 'JOB_CHECK_IN_REVIEW',
  JobCheckPending = 'JOB_CHECK_PENDING',
  JobCheckSucceeded = 'JOB_CHECK_SUCCEEDED',
  JournalCreated = 'JOURNAL_CREATED',
  MagicLinkCreated = 'MAGIC_LINK_CREATED',
  SignatoryDocumentStatusChanged = 'SIGNATORY_DOCUMENT_STATUS_CHANGED',
  SignatoryDownloadLinkOpened = 'SIGNATORY_DOWNLOAD_LINK_OPENED',
  SignatoryRejected = 'SIGNATORY_REJECTED',
  SignatorySigned = 'SIGNATORY_SIGNED',
  SignatorySignError = 'SIGNATORY_SIGN_ERROR',
  SignatorySignLinkOpened = 'SIGNATORY_SIGN_LINK_OPENED',
  SignatureOrderExpired = 'SIGNATURE_ORDER_EXPIRED',
  TeamAccountRegistered = 'TEAM_ACCOUNT_REGISTERED',
  TeamAccountUpdated = 'TEAM_ACCOUNT_UPDATED',
  UserUpdated = 'USER_UPDATED'
}

export type ExpenseInvoiceLine = InvoiceLine & Node & {
  __typename?: 'ExpenseInvoiceLine';
  amount: Scalars['Int']['output'];
  collapsed: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  invoice: Invoice;
  lineType: LineTypeEnum;
  occupationCode: OccupationCode;
  quantity: Scalars['Float']['output'];
  remainingQuantity: Scalars['Float']['output'];
  unitPrice: Scalars['Int']['output'];
  vat: Scalars['Int']['output'];
  vatRate: Scalars['Float']['output'];
};

export type ExtraInvoiceLine = InvoiceLine & Node & {
  __typename?: 'ExtraInvoiceLine';
  amount: Scalars['Int']['output'];
  collapsed: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  invoice: Invoice;
  lineType: LineTypeEnum;
  occupationCode: OccupationCode;
  quantity: Scalars['Float']['output'];
  remainingQuantity: Scalars['Float']['output'];
  unitPrice: Scalars['Int']['output'];
  vat: Scalars['Int']['output'];
  vatRate: Scalars['Float']['output'];
};

export type FeatureCounts = {
  __typename?: 'FeatureCounts';
  accessControl?: Maybe<Scalars['Int']['output']>;
  advancedCaseManagement?: Maybe<Scalars['Int']['output']>;
  archive?: Maybe<Scalars['Int']['output']>;
  bulkSign?: Maybe<Scalars['Int']['output']>;
  criminalRecordCheck?: Maybe<Scalars['Int']['output']>;
  customApiIntegrations?: Maybe<Scalars['Int']['output']>;
  customBranding?: Maybe<Scalars['Int']['output']>;
  customELearning?: Maybe<Scalars['Int']['output']>;
  customisedSolution?: Maybe<Scalars['Int']['output']>;
  departments?: Maybe<Scalars['Int']['output']>;
  duplicateFilter?: Maybe<Scalars['Int']['output']>;
  encryption?: Maybe<Scalars['Int']['output']>;
  export?: Maybe<Scalars['Int']['output']>;
  feedback?: Maybe<Scalars['Int']['output']>;
  feedbackActivation?: Maybe<Scalars['Int']['output']>;
  import?: Maybe<Scalars['Int']['output']>;
  keyAccountManager?: Maybe<Scalars['Int']['output']>;
  members?: Maybe<Scalars['Int']['output']>;
  modules?: Maybe<Scalars['Int']['output']>;
  onboardingTraining?: Maybe<Scalars['Int']['output']>;
  qrCodes?: Maybe<Scalars['Int']['output']>;
  unlimitedStorage?: Maybe<Scalars['Int']['output']>;
  voiceReporting?: Maybe<Scalars['Int']['output']>;
  wallet?: Maybe<Scalars['Int']['output']>;
  whistleblowing?: Maybe<Scalars['Int']['output']>;
};

export enum FeatureTypeEnum {
  AccessControl = 'ACCESS_CONTROL',
  AdvancedCaseManagement = 'ADVANCED_CASE_MANAGEMENT',
  Archive = 'ARCHIVE',
  BulkSign = 'BULK_SIGN',
  CriminalRecordCheck = 'CRIMINAL_RECORD_CHECK',
  CustomisedSolution = 'CUSTOMISED_SOLUTION',
  CustomApiIntegrations = 'CUSTOM_API_INTEGRATIONS',
  CustomBranding = 'CUSTOM_BRANDING',
  CustomELearning = 'CUSTOM_E_LEARNING',
  Departments = 'DEPARTMENTS',
  DuplicateFilter = 'DUPLICATE_FILTER',
  Encryption = 'ENCRYPTION',
  Export = 'EXPORT',
  Feedback = 'FEEDBACK',
  FeedbackActivation = 'FEEDBACK_ACTIVATION',
  Import = 'IMPORT',
  KeyAccountManager = 'KEY_ACCOUNT_MANAGER',
  Modules = 'MODULES',
  OnboardingTraining = 'ONBOARDING_TRAINING',
  QrCodes = 'QR_CODES',
  UnlimitedStorage = 'UNLIMITED_STORAGE',
  VoiceReporting = 'VOICE_REPORTING',
  Wallet = 'WALLET',
  Whistleblowing = 'WHISTLEBLOWING'
}

/** Autogenerated input type of FindOrCreateJobApplication */
export type FindOrCreateJobApplicationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  jobId: Scalars['ID']['input'];
};

/** Autogenerated return type of FindOrCreateJobApplication. */
export type FindOrCreateJobApplicationPayload = {
  __typename?: 'FindOrCreateJobApplicationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  jobApplication?: Maybe<JobApplication>;
};

export type Form = {
  __typename?: 'Form';
  pages: Array<FormPage>;
};

export type FormAnswer = {
  __typename?: 'FormAnswer';
  questionId: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
  values?: Maybe<Array<Scalars['String']['output']>>;
};

export type FormAnswerAttributes = {
  questionId: Scalars['String']['input'];
  value?: InputMaybe<Scalars['String']['input']>;
  values?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type FormAttributes = {
  pages: Array<FormPageAttributes>;
};

export type FormCondition = {
  __typename?: 'FormCondition';
  matchValues: Array<Scalars['String']['output']>;
  questionId: Scalars['String']['output'];
  type: FormConditionTypeEnum;
};

export type FormConditionAttributes = {
  matchValues: Array<Scalars['String']['input']>;
  questionId: Scalars['String']['input'];
  type: FormConditionTypeEnum;
};

export enum FormConditionTypeEnum {
  HideIfMatch = 'HIDE_IF_MATCH',
  ShowIfMatch = 'SHOW_IF_MATCH'
}

export type FormOption = {
  __typename?: 'FormOption';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type FormOptionAttributes = {
  label: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type FormPage = {
  __typename?: 'FormPage';
  description: Scalars['String']['output'];
  pageNumber: Scalars['Int']['output'];
  questions: Array<FormQuestion>;
  title: Scalars['String']['output'];
};

export type FormPageAttributes = {
  description: Scalars['String']['input'];
  pageNumber: Scalars['Int']['input'];
  questions: Array<FormQuestionAttributes>;
  title: Scalars['String']['input'];
};

export type FormQuestion = {
  __typename?: 'FormQuestion';
  condition?: Maybe<FormCondition>;
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
  options?: Maybe<Array<FormOption>>;
  required: Scalars['Boolean']['output'];
  type: FormQuestionTypeEnum;
  validationMessage?: Maybe<Scalars['String']['output']>;
  validationRegexp?: Maybe<Scalars['String']['output']>;
};

export type FormQuestionAttributes = {
  condition?: InputMaybe<FormConditionAttributes>;
  id: Scalars['String']['input'];
  label: Scalars['String']['input'];
  options?: InputMaybe<Array<FormOptionAttributes>>;
  required: Scalars['Boolean']['input'];
  type: FormQuestionTypeEnum;
  validationMessage?: InputMaybe<Scalars['String']['input']>;
  validationRegexp?: InputMaybe<Scalars['String']['input']>;
};

export enum FormQuestionTypeEnum {
  Checkboxes = 'CHECKBOXES',
  Radios = 'RADIOS',
  Text = 'TEXT'
}

export type FreelanceProfile = Node & {
  __typename?: 'FreelanceProfile';
  address?: Maybe<Address>;
  agreementPdf?: Maybe<Attachment>;
  archivedAt?: Maybe<Scalars['String']['output']>;
  bankAccounts?: Maybe<Array<BankAccount>>;
  defaultBankAccount?: Maybe<BankAccount>;
  firstName?: Maybe<Scalars['String']['output']>;
  freelanceType: FreelanceTypeEnum;
  id: Scalars['ID']['output'];
  invoiceLogo?: Maybe<Attachment>;
  invoiceStartingNumber?: Maybe<Scalars['Int']['output']>;
  jobChecks?: Maybe<Array<JobCheck>>;
  journalClosedMonth?: Maybe<Scalars['String']['output']>;
  journalFormat?: Maybe<JournalFormatEnum>;
  journals?: Maybe<Array<Journal>>;
  lastName?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  organizationName?: Maybe<Scalars['String']['output']>;
  organizationNumber?: Maybe<Scalars['String']['output']>;
  referencePeople: Array<ReferencePerson>;
  taxCardStatus?: Maybe<Scalars['Boolean']['output']>;
  verified: Scalars['Boolean']['output'];
};

export type FreelanceProfileLookup = {
  __typename?: 'FreelanceProfileLookup';
  belongsToAccount: Scalars['Boolean']['output'];
  company?: Maybe<Company>;
  freelanceProfile?: Maybe<FreelanceProfile>;
};

export type FreelanceProfilePagination = {
  __typename?: 'FreelanceProfilePagination';
  exportPath?: Maybe<Scalars['String']['output']>;
  items: Array<FreelanceProfile>;
  total: Scalars['Int']['output'];
};

export type FreelanceProfilesFilter = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  freelanceType?: InputMaybe<FreelanceTypeEnum>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export enum FreelanceProfilesSortByEnum {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC'
}

export enum FreelanceTypeEnum {
  Individual = 'INDIVIDUAL',
  Organization = 'ORGANIZATION'
}

export type HelpArticle = {
  __typename?: 'HelpArticle';
  blockManualInvoicing: Scalars['Boolean']['output'];
  enHelpArticleUrl?: Maybe<Scalars['String']['output']>;
  enMessage?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nbHelpArticleUrl?: Maybe<Scalars['String']['output']>;
  nbMessage?: Maybe<Scalars['String']['output']>;
  showAttachmentWarning: Scalars['Boolean']['output'];
  supportEmail?: Maybe<Scalars['String']['output']>;
};

export type Identity = Node & {
  __typename?: 'Identity';
  country: CountryCodeEnum;
  createdAt: Scalars['String']['output'];
  dateOfBirth: Scalars['String']['output'];
  default: Scalars['Boolean']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  identifier: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  temporary: Scalars['Boolean']['output'];
  updatedAt: Scalars['String']['output'];
};

export enum IdentityCountryCodeEnum {
  Dk = 'DK',
  Fi = 'FI',
  No = 'NO',
  Se = 'SE'
}

export type Invoice = Node & {
  __typename?: 'Invoice';
  amount: Scalars['Int']['output'];
  approvedAt?: Maybe<Scalars['String']['output']>;
  bankAccount?: Maybe<BankAccount>;
  bankAccountNumber?: Maybe<Scalars['String']['output']>;
  bankSwiftCode?: Maybe<Scalars['String']['output']>;
  bundled: Scalars['Boolean']['output'];
  buyerReference?: Maybe<Scalars['String']['output']>;
  client?: Maybe<Client>;
  clientReferencePerson?: Maybe<ReferencePerson>;
  createdAt: Scalars['String']['output'];
  creditNotes: Array<CreditNote>;
  currency?: Maybe<CurrencyEnum>;
  dueOn?: Maybe<Scalars['String']['output']>;
  ehfSentAt?: Maybe<Scalars['String']['output']>;
  emailSentAt?: Maybe<Scalars['String']['output']>;
  employerTax?: Maybe<Scalars['Float']['output']>;
  employerTaxAmount?: Maybe<Scalars['Int']['output']>;
  events?: Maybe<Array<InvoiceEvent>>;
  freelanceProfile: FreelanceProfile;
  grossSalary?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  invoiceNumber?: Maybe<Scalars['String']['output']>;
  invoicedOn?: Maybe<Scalars['String']['output']>;
  kid?: Maybe<Scalars['String']['output']>;
  lines: Array<InvoiceLine>;
  nullifiedAt?: Maybe<Scalars['String']['output']>;
  orderReference?: Maybe<Scalars['String']['output']>;
  payoutAmount?: Maybe<Scalars['Int']['output']>;
  pdfDocument?: Maybe<Attachment>;
  platformFee?: Maybe<Scalars['Float']['output']>;
  platformFeeAmount?: Maybe<Scalars['Int']['output']>;
  publishedAt?: Maybe<Scalars['String']['output']>;
  receipts: Array<Receipt>;
  refundStatus: InvoiceRefundStatusEnum;
  rejectedAt?: Maybe<Scalars['String']['output']>;
  remainingAmount: Scalars['Int']['output'];
  remainingEmployerTaxAmount: Scalars['Int']['output'];
  remainingGrossSalary: Scalars['Int']['output'];
  remainingPayoutAmount: Scalars['Int']['output'];
  remainingPlatformFeeAmount: Scalars['Int']['output'];
  remainingSalaryTaxAmount: Scalars['Int']['output'];
  remainingVat: Scalars['Int']['output'];
  resolvedAt?: Maybe<Scalars['String']['output']>;
  salaryTax?: Maybe<Scalars['Float']['output']>;
  salaryTaxAmount?: Maybe<Scalars['Int']['output']>;
  senderReferencePerson?: Maybe<ReferencePerson>;
  senderVatRegistered: Scalars['Boolean']['output'];
  status: InvoiceStatusEnum;
  submittedAt?: Maybe<Scalars['String']['output']>;
  transfers?: Maybe<Array<Transfer>>;
  vat: Scalars['Int']['output'];
};

export type InvoiceEvent = {
  __typename?: 'InvoiceEvent';
  actor: ActorEnum;
  eventType: InvoiceEventTypeEnum;
  occurredAt: Scalars['String']['output'];
  status: InvoiceStatusEnum;
};

export enum InvoiceEventTypeEnum {
  InvoiceApproved = 'INVOICE_APPROVED',
  InvoiceApprovedForPayout = 'INVOICE_APPROVED_FOR_PAYOUT',
  InvoiceCredited = 'INVOICE_CREDITED',
  InvoiceHidden = 'INVOICE_HIDDEN',
  InvoiceNullified = 'INVOICE_NULLIFIED',
  InvoicePaidOut = 'INVOICE_PAID_OUT',
  InvoicePublished = 'INVOICE_PUBLISHED',
  InvoiceRejected = 'INVOICE_REJECTED',
  InvoiceRejectedForPayout = 'INVOICE_REJECTED_FOR_PAYOUT',
  InvoiceResolved = 'INVOICE_RESOLVED',
  InvoiceSentManually = 'INVOICE_SENT_MANUALLY',
  InvoiceSentViaEhf = 'INVOICE_SENT_VIA_EHF',
  InvoiceSentViaEmail = 'INVOICE_SENT_VIA_EMAIL',
  InvoiceSubmitted = 'INVOICE_SUBMITTED',
  InvoiceUnapproved = 'INVOICE_UNAPPROVED',
  InvoiceUnapprovedForPayout = 'INVOICE_UNAPPROVED_FOR_PAYOUT',
  InvoiceUnpublished = 'INVOICE_UNPUBLISHED',
  InvoiceUnrejected = 'INVOICE_UNREJECTED',
  InvoiceVoided = 'INVOICE_VOIDED'
}

export type InvoiceLine = {
  amount: Scalars['Int']['output'];
  collapsed: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  invoice: Invoice;
  lineType: LineTypeEnum;
  occupationCode: OccupationCode;
  quantity: Scalars['Float']['output'];
  remainingQuantity: Scalars['Float']['output'];
  unitPrice: Scalars['Int']['output'];
  vat: Scalars['Int']['output'];
  vatRate: Scalars['Float']['output'];
};

export type InvoicePagination = {
  __typename?: 'InvoicePagination';
  exportPath?: Maybe<Scalars['String']['output']>;
  items: Array<Invoice>;
  total: Scalars['Int']['output'];
};

export enum InvoiceRefundStatusEnum {
  Full = 'FULL',
  No = 'NO',
  Partial = 'PARTIAL',
  Pending = 'PENDING'
}

export enum InvoiceStatusEnum {
  Approved = 'APPROVED',
  Draft = 'DRAFT',
  Nullified = 'NULLIFIED',
  Published = 'PUBLISHED',
  Rejected = 'REJECTED',
  Resolved = 'RESOLVED',
  Submitted = 'SUBMITTED'
}

export type InvoicesFilter = {
  amountFrom?: InputMaybe<Scalars['Int']['input']>;
  amountnTo?: InputMaybe<Scalars['Int']['input']>;
  clientExclude?: InputMaybe<Array<Scalars['ID']['input']>>;
  clientInclude?: InputMaybe<Array<Scalars['ID']['input']>>;
  freelanceProfileId?: InputMaybe<Scalars['ID']['input']>;
  invoicedOnFrom?: InputMaybe<Scalars['String']['input']>;
  invoicedOnTo?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  statusExclude?: InputMaybe<Array<InvoiceStatusEnum>>;
  statusInclude?: InputMaybe<Array<InvoiceStatusEnum>>;
  submittedAtFrom?: InputMaybe<Scalars['String']['input']>;
  submittedAtTo?: InputMaybe<Scalars['String']['input']>;
};

export enum InvoicesSortByEnum {
  AmountAsc = 'AMOUNT_ASC',
  AmountDesc = 'AMOUNT_DESC',
  ClientNameAsc = 'CLIENT_NAME_ASC',
  ClientNameDesc = 'CLIENT_NAME_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  InvoiceNumberAsc = 'INVOICE_NUMBER_ASC',
  InvoiceNumberDesc = 'INVOICE_NUMBER_DESC',
  SubmittedAtAsc = 'SUBMITTED_AT_ASC',
  SubmittedAtDesc = 'SUBMITTED_AT_DESC'
}

export type Job = Node & {
  __typename?: 'Job';
  account: Account;
  address?: Maybe<Address>;
  applicationDeadline?: Maybe<Scalars['String']['output']>;
  applicationForm?: Maybe<Form>;
  applicationLimit?: Maybe<Scalars['Int']['output']>;
  approvedApplications?: Maybe<Scalars['Int']['output']>;
  colors: PartnerColors;
  compensation?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  enrolledApplications?: Maybe<Scalars['Int']['output']>;
  enrollment?: Maybe<Enrollment>;
  id: Scalars['ID']['output'];
  jobChecks: Array<JobCheckConfig>;
  jobType?: Maybe<Scalars['String']['output']>;
  languageRequirements?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<Attachment>;
  partnerLogo?: Maybe<Attachment>;
  partnerPrivacyLink?: Maybe<Scalars['String']['output']>;
  partnerTermsLink?: Maybe<Scalars['String']['output']>;
  payout?: Maybe<Scalars['String']['output']>;
  privacyLink?: Maybe<Scalars['String']['output']>;
  qualifiedApplications?: Maybe<Scalars['Int']['output']>;
  rejectedApplications?: Maybe<Scalars['Int']['output']>;
  submittedApplications?: Maybe<Scalars['Int']['output']>;
  tagline?: Maybe<Scalars['String']['output']>;
  termsLink?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type JobApplication = Node & {
  __typename?: 'JobApplication';
  applicationFormAnswers?: Maybe<Array<FormAnswer>>;
  email: Scalars['String']['output'];
  enrollment?: Maybe<Enrollment>;
  id: Scalars['ID']['output'];
  job: Job;
  name?: Maybe<Scalars['String']['output']>;
  status: JobApplicationStatusEnum;
  submittedJobChecks: Array<JobCheckTypeEnum>;
};

export type JobApplicationPagination = {
  __typename?: 'JobApplicationPagination';
  exportPath?: Maybe<Scalars['String']['output']>;
  items: Array<JobApplication>;
  total: Scalars['Int']['output'];
};

export enum JobApplicationStatusEnum {
  Approved = 'APPROVED',
  Enrolled = 'ENROLLED',
  Pending = 'PENDING',
  Qualified = 'QUALIFIED',
  Rejected = 'REJECTED',
  Submitted = 'SUBMITTED'
}

export type JobApplicationsFilter = {
  email?: InputMaybe<Scalars['String']['input']>;
  jobId?: InputMaybe<Array<Scalars['ID']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<JobApplicationStatusEnum>;
};

export type JobCheck = Node & {
  __typename?: 'JobCheck';
  content?: Maybe<Scalars['String']['output']>;
  documents?: Maybe<Array<Attachment>>;
  freelanceProfile?: Maybe<FreelanceProfile>;
  id: Scalars['ID']['output'];
  jobCheckType: JobCheckTypeEnum;
  status: JobCheckStatusEnum;
};

export type JobCheckAttributes = {
  content?: InputMaybe<Scalars['String']['input']>;
  documents?: InputMaybe<Array<Base64AttachmentAttributes>>;
  freelanceProfileId?: InputMaybe<Scalars['ID']['input']>;
  jobCheckType: JobCheckTypeEnum;
};

export type JobCheckConfig = {
  __typename?: 'JobCheckConfig';
  condition?: Maybe<FormCondition>;
  id: JobCheckTypeEnum;
};

export type JobCheckConfigAttributes = {
  condition?: InputMaybe<FormConditionAttributes>;
  id: JobCheckTypeEnum;
};

export type JobCheckPagination = {
  __typename?: 'JobCheckPagination';
  exportPath?: Maybe<Scalars['String']['output']>;
  items: Array<JobCheck>;
  total: Scalars['Int']['output'];
};

export enum JobCheckStatusEnum {
  Failed = 'FAILED',
  InReview = 'IN_REVIEW',
  Pending = 'PENDING',
  Succeeded = 'SUCCEEDED'
}

export enum JobCheckTypeEnum {
  BankAccount = 'BANK_ACCOUNT',
  Credit = 'CREDIT',
  DriversLicense = 'DRIVERS_LICENSE',
  HealthPersonnelRegister = 'HEALTH_PERSONNEL_REGISTER',
  HseCard = 'HSE_CARD',
  Identity = 'IDENTITY',
  RightToWork = 'RIGHT_TO_WORK'
}

export type JobPagination = {
  __typename?: 'JobPagination';
  exportPath?: Maybe<Scalars['String']['output']>;
  items: Array<Job>;
  total: Scalars['Int']['output'];
};

export type Journal = Node & {
  __typename?: 'Journal';
  amountWithVat: Scalars['Int']['output'];
  exportType: JournalTypeEnum;
  format: JournalFormatEnum;
  id: Scalars['ID']['output'];
  month: Scalars['String']['output'];
  sequenceNumber: Scalars['Int']['output'];
};

export type JournalAttributes = {
  exportType: JournalTypeEnum;
  freelanceProfileId: Scalars['ID']['input'];
  month: Scalars['String']['input'];
};

export enum JournalFormatEnum {
  Tripletex = 'TRIPLETEX',
  Visma = 'VISMA'
}

export enum JournalTypeEnum {
  CreditNote = 'CREDIT_NOTE',
  Invoice = 'INVOICE'
}

export enum LanguageEnum {
  En = 'EN',
  Nb = 'NB'
}

export type LegacyFeatures = {
  __typename?: 'LegacyFeatures';
  checks: Scalars['Boolean']['output'];
  eSignatures: Scalars['Boolean']['output'];
  invoices: Scalars['Boolean']['output'];
  payouts: Scalars['Boolean']['output'];
  whistleblowing: Scalars['Boolean']['output'];
  workflows: Scalars['Boolean']['output'];
};

export enum LineTypeEnum {
  Benefit = 'BENEFIT',
  Diet = 'DIET',
  Expense = 'EXPENSE',
  Extra = 'EXTRA',
  Mileage = 'MILEAGE',
  Work = 'WORK'
}

export type ListCompany = {
  __typename?: 'ListCompany';
  accountExists: Scalars['Boolean']['output'];
  address?: Maybe<Address>;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  markedAsBankruptcy?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  orgNumber: Scalars['String']['output'];
  underForcedLiquidation?: Maybe<Scalars['Boolean']['output']>;
  underLiquidation?: Maybe<Scalars['Boolean']['output']>;
};

export type ListCompanyPagination = {
  __typename?: 'ListCompanyPagination';
  exportPath?: Maybe<Scalars['String']['output']>;
  items: Array<ListCompany>;
  total: Scalars['Int']['output'];
};

export type MembersFilter = {
  confirmed?: InputMaybe<Scalars['Boolean']['input']>;
  departmentId?: InputMaybe<Scalars['ID']['input']>;
  isWbHandler?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type MileageInvoiceLine = InvoiceLine & Node & {
  __typename?: 'MileageInvoiceLine';
  addressFrom?: Maybe<Scalars['String']['output']>;
  addressTo?: Maybe<Scalars['String']['output']>;
  amount: Scalars['Int']['output'];
  collapsed: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  invoice: Invoice;
  lineType: LineTypeEnum;
  occupationCode: OccupationCode;
  quantity: Scalars['Float']['output'];
  remainingQuantity: Scalars['Float']['output'];
  unitPrice: Scalars['Int']['output'];
  vat: Scalars['Int']['output'];
  vatRate: Scalars['Float']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addAccountMember?: Maybe<AddAccountMemberPayload>;
  approveEnrollment?: Maybe<ApproveEnrollmentPayload>;
  approveJobApplication?: Maybe<ApproveJobApplicationPayload>;
  archiveCheckLists?: Maybe<ArchiveCheckListsPayload>;
  archiveClient?: Maybe<ArchiveClientPayload>;
  archiveDocuments?: Maybe<ArchiveDocumentsPayload>;
  archiveFreelanceProfile?: Maybe<ArchiveFreelanceProfilePayload>;
  archivePeople?: Maybe<ArchivePeoplePayload>;
  cancelAccountDeletion?: Maybe<CancelAccountDeletionPayload>;
  cancelCheckLists?: Maybe<CancelCheckListsPayload>;
  cancelDocuments?: Maybe<CancelDocumentsPayload>;
  cancelSubscription?: Maybe<CancelSubscriptionPayload>;
  closeWbReport?: Maybe<CloseWbReportPayload>;
  consentCheck?: Maybe<ConsentCheckPayload>;
  consentCheckList?: Maybe<ConsentCheckListPayload>;
  createBankAccount?: Maybe<CreateBankAccountPayload>;
  createCheckLists?: Maybe<CreateCheckListsPayload>;
  createCheckRequest?: Maybe<CreateCheckRequestPayload>;
  createClient?: Maybe<CreateClientPayload>;
  createCreditNote?: Maybe<CreateCreditNotePayload>;
  createDepartment?: Maybe<CreateDepartmentPayload>;
  createDocument?: Maybe<CreateDocumentPayload>;
  createDocumentFile?: Maybe<CreateDocumentFilePayload>;
  createFreelanceProfile?: Maybe<CreateFreelanceProfilePayload>;
  createInvoice?: Maybe<CreateInvoicePayload>;
  createInvoiceLine?: Maybe<CreateInvoiceLinePayload>;
  createJob?: Maybe<CreateJobPayload>;
  createJobApplication?: Maybe<CreateJobApplicationPayload>;
  createJobCheck?: Maybe<CreateJobCheckPayload>;
  createJournal?: Maybe<CreateJournalPayload>;
  createPaymentMethod?: Maybe<CreatePaymentMethodPayload>;
  createPolicyDocument?: Maybe<CreatePolicyDocumentPayload>;
  createReceipt?: Maybe<CreateReceiptPayload>;
  createRoutineGuideline?: Maybe<CreateRoutineGuidelinePayload>;
  createSession?: Maybe<CreateSessionPayload>;
  createSessionOtp?: Maybe<CreateSessionOtpPayload>;
  createSignatures?: Maybe<CreateSignaturesPayload>;
  createStripeCard?: Maybe<CreateStripeCardPayload>;
  createStripeSetupIntent?: Maybe<CreateStripeSetupIntentPayload>;
  createTeamAccount?: Maybe<CreateTeamAccountPayload>;
  createTransfer?: Maybe<CreateTransferPayload>;
  createWbHandler?: Maybe<CreateWbHandlerPayload>;
  createWbMessage?: Maybe<CreateWbMessagePayload>;
  createWbReport?: Maybe<CreateWbReportPayload>;
  createWbReportFile?: Maybe<CreateWbReportFilePayload>;
  criiptoVerify?: Maybe<CriiptoVerifyPayload>;
  deleteAccount?: Maybe<DeleteAccountPayload>;
  deleteIdentity?: Maybe<DeleteIdentityPayload>;
  deleteWbReport?: Maybe<DeleteWbReportPayload>;
  destroyAccountLogo?: Maybe<DestroyAccountLogoPayload>;
  destroyAccountSymbol?: Maybe<DestroyAccountSymbolPayload>;
  destroyBankAccount?: Maybe<DestroyBankAccountPayload>;
  destroyCheckList?: Maybe<DestroyCheckListPayload>;
  destroyCheckRequestFile?: Maybe<DestroyCheckRequestFilePayload>;
  destroyDepartment?: Maybe<DestroyDepartmentPayload>;
  destroyDocumentFile?: Maybe<DestroyDocumentFilePayload>;
  destroyDocuments?: Maybe<DestroyDocumentsPayload>;
  destroyInvoice?: Maybe<DestroyInvoicePayload>;
  destroyInvoiceLine?: Maybe<DestroyInvoiceLinePayload>;
  destroyPaymentMethod?: Maybe<DestroyPaymentMethodPayload>;
  destroyPeople?: Maybe<DestroyPeoplePayload>;
  destroyPolicyDocument?: Maybe<DestroyPolicyDocumentPayload>;
  destroyReceipt?: Maybe<DestroyReceiptPayload>;
  destroyRoutineGuideline?: Maybe<DestroyRoutineGuidelinePayload>;
  destroySignature?: Maybe<DestroySignaturePayload>;
  destroyTransfer?: Maybe<DestroyTransferPayload>;
  destroyWbHandler?: Maybe<DestroyWbHandlerPayload>;
  destroyWbReportFile?: Maybe<DestroyWbReportFilePayload>;
  duplicateInvoice?: Maybe<DuplicateInvoicePayload>;
  findOrCreateJobApplication?: Maybe<FindOrCreateJobApplicationPayload>;
  notifyDocuments?: Maybe<NotifyDocumentsPayload>;
  notifySignature?: Maybe<NotifySignaturePayload>;
  onboard?: Maybe<OnboardPayload>;
  overrideCheck?: Maybe<OverrideCheckPayload>;
  prevalidateCheckFile?: Maybe<PrevalidateCheckFilePayload>;
  publicCreateWbMessage?: Maybe<PublicCreateWbMessagePayload>;
  publicUpdateWbReport?: Maybe<PublicUpdateWbReportPayload>;
  refreshSessionOtp?: Maybe<RefreshSessionOtpPayload>;
  refuseCheck?: Maybe<RefuseCheckPayload>;
  refuseCheckList?: Maybe<RefuseCheckListPayload>;
  refuseDocument?: Maybe<RefuseDocumentPayload>;
  registerAccount?: Maybe<RegisterAccountPayload>;
  registerTeamAccount?: Maybe<RegisterTeamAccountPayload>;
  rejectJobApplication?: Maybe<RejectJobApplicationPayload>;
  remindCheckLists?: Maybe<RemindCheckListsPayload>;
  removeAccountMember?: Maybe<RemoveAccountMemberPayload>;
  reopenWbReport?: Maybe<ReopenWbReportPayload>;
  resetPassword?: Maybe<ResetPasswordPayload>;
  resolveInvoice?: Maybe<ResolveInvoicePayload>;
  sendCreditNoteViaEhf?: Maybe<SendCreditNoteViaEhfPayload>;
  sendCreditNoteViaEmail?: Maybe<SendCreditNoteViaEmailPayload>;
  sendDocument?: Maybe<SendDocumentPayload>;
  sendInvoiceViaEhf?: Maybe<SendInvoiceViaEhfPayload>;
  sendInvoiceViaEmail?: Maybe<SendInvoiceViaEmailPayload>;
  sendPasswordResetInstructions?: Maybe<SendPasswordResetInstructionsPayload>;
  sendSmsConfirmation?: Maybe<SendSmsConfirmationPayload>;
  sendSupportMessage?: Maybe<SendSupportMessagePayload>;
  sendTeamWelcome?: Maybe<SendTeamWelcomePayload>;
  setAccountOwner?: Maybe<SetAccountOwnerPayload>;
  setDefaultBankAccount?: Maybe<SetDefaultBankAccountPayload>;
  setDefaultIdentity?: Maybe<SetDefaultIdentityPayload>;
  setDefaultPaymentMethod?: Maybe<SetDefaultPaymentMethodPayload>;
  setDefaultWbHandler?: Maybe<SetDefaultWbHandlerPayload>;
  setEnrollment?: Maybe<SetEnrollmentPayload>;
  setPeopleDepartments?: Maybe<SetPeopleDepartmentsPayload>;
  setTeamRole?: Maybe<SetTeamRolePayload>;
  signDocument?: Maybe<SignDocumentPayload>;
  submitCheck?: Maybe<SubmitCheckPayload>;
  submitCheckRequest?: Maybe<SubmitCheckRequestPayload>;
  submitDocument?: Maybe<SubmitDocumentPayload>;
  submitInvoice?: Maybe<SubmitInvoicePayload>;
  submitJobApplication?: Maybe<SubmitJobApplicationPayload>;
  submitWbReport?: Maybe<SubmitWbReportPayload>;
  switchAccount?: Maybe<SwitchAccountPayload>;
  terminateDocument?: Maybe<TerminateDocumentPayload>;
  unarchiveCheckLists?: Maybe<UnarchiveCheckListsPayload>;
  unarchiveDocuments?: Maybe<UnarchiveDocumentsPayload>;
  unarchiveFreelanceProfile?: Maybe<UnarchiveFreelanceProfilePayload>;
  unarchivePeople?: Maybe<UnarchivePeoplePayload>;
  updateAccount?: Maybe<UpdateAccountPayload>;
  updateAccountMember?: Maybe<UpdateAccountMemberPayload>;
  updateCheckList?: Maybe<UpdateCheckListPayload>;
  updateCheckRequest?: Maybe<UpdateCheckRequestPayload>;
  updateClient?: Maybe<UpdateClientPayload>;
  updateDepartment?: Maybe<UpdateDepartmentPayload>;
  updateDocument?: Maybe<UpdateDocumentPayload>;
  updateFreelanceProfile?: Maybe<UpdateFreelanceProfilePayload>;
  updateInvoice?: Maybe<UpdateInvoicePayload>;
  updateInvoiceLine?: Maybe<UpdateInvoiceLinePayload>;
  updateJob?: Maybe<UpdateJobPayload>;
  updateJobApplication?: Maybe<UpdateJobApplicationPayload>;
  updatePaymentMethod?: Maybe<UpdatePaymentMethodPayload>;
  updateSubscription?: Maybe<UpdateSubscriptionPayload>;
  updateTransfer?: Maybe<UpdateTransferPayload>;
  updateUser?: Maybe<UpdateUserPayload>;
  updateWbHandler?: Maybe<UpdateWbHandlerPayload>;
  updateWbReport?: Maybe<UpdateWbReportPayload>;
  uploadCheckLists?: Maybe<UploadCheckListsPayload>;
  uploadCheckRequestFile?: Maybe<UploadCheckRequestFilePayload>;
  uploadSignatures?: Maybe<UploadSignaturesPayload>;
  useSessionOtp?: Maybe<UseSessionOtpPayload>;
  validatePasswordResetToken?: Maybe<ValidatePasswordResetTokenPayload>;
  verifyPhoneNumber?: Maybe<VerifyPhoneNumberPayload>;
};


export type MutationAddAccountMemberArgs = {
  input: AddAccountMemberInput;
};


export type MutationApproveEnrollmentArgs = {
  input: ApproveEnrollmentInput;
};


export type MutationApproveJobApplicationArgs = {
  input: ApproveJobApplicationInput;
};


export type MutationArchiveCheckListsArgs = {
  input: ArchiveCheckListsInput;
};


export type MutationArchiveClientArgs = {
  input: ArchiveClientInput;
};


export type MutationArchiveDocumentsArgs = {
  input: ArchiveDocumentsInput;
};


export type MutationArchiveFreelanceProfileArgs = {
  input: ArchiveFreelanceProfileInput;
};


export type MutationArchivePeopleArgs = {
  input: ArchivePeopleInput;
};


export type MutationCancelAccountDeletionArgs = {
  input: CancelAccountDeletionInput;
};


export type MutationCancelCheckListsArgs = {
  input: CancelCheckListsInput;
};


export type MutationCancelDocumentsArgs = {
  input: CancelDocumentsInput;
};


export type MutationCancelSubscriptionArgs = {
  input: CancelSubscriptionInput;
};


export type MutationCloseWbReportArgs = {
  input: CloseWbReportInput;
};


export type MutationConsentCheckArgs = {
  input: ConsentCheckInput;
};


export type MutationConsentCheckListArgs = {
  input: ConsentCheckListInput;
};


export type MutationCreateBankAccountArgs = {
  input: CreateBankAccountInput;
};


export type MutationCreateCheckListsArgs = {
  input: CreateCheckListsInput;
};


export type MutationCreateCheckRequestArgs = {
  input: CreateCheckRequestInput;
};


export type MutationCreateClientArgs = {
  input: CreateClientInput;
};


export type MutationCreateCreditNoteArgs = {
  input: CreateCreditNoteInput;
};


export type MutationCreateDepartmentArgs = {
  input: CreateDepartmentInput;
};


export type MutationCreateDocumentArgs = {
  input: CreateDocumentInput;
};


export type MutationCreateDocumentFileArgs = {
  input: CreateDocumentFileInput;
};


export type MutationCreateFreelanceProfileArgs = {
  input: CreateFreelanceProfileInput;
};


export type MutationCreateInvoiceArgs = {
  input: CreateInvoiceInput;
};


export type MutationCreateInvoiceLineArgs = {
  input: CreateInvoiceLineInput;
};


export type MutationCreateJobArgs = {
  input: CreateJobInput;
};


export type MutationCreateJobApplicationArgs = {
  input: CreateJobApplicationInput;
};


export type MutationCreateJobCheckArgs = {
  input: CreateJobCheckInput;
};


export type MutationCreateJournalArgs = {
  input: CreateJournalInput;
};


export type MutationCreatePaymentMethodArgs = {
  input: CreatePaymentMethodInput;
};


export type MutationCreatePolicyDocumentArgs = {
  input: CreatePolicyDocumentInput;
};


export type MutationCreateReceiptArgs = {
  input: CreateReceiptInput;
};


export type MutationCreateRoutineGuidelineArgs = {
  input: CreateRoutineGuidelineInput;
};


export type MutationCreateSessionArgs = {
  input: CreateSessionInput;
};


export type MutationCreateSessionOtpArgs = {
  input: CreateSessionOtpInput;
};


export type MutationCreateSignaturesArgs = {
  input: CreateSignaturesInput;
};


export type MutationCreateStripeCardArgs = {
  input: CreateStripeCardInput;
};


export type MutationCreateStripeSetupIntentArgs = {
  input: CreateStripeSetupIntentInput;
};


export type MutationCreateTeamAccountArgs = {
  input: CreateTeamAccountInput;
};


export type MutationCreateTransferArgs = {
  input: CreateTransferInput;
};


export type MutationCreateWbHandlerArgs = {
  input: CreateWbHandlerInput;
};


export type MutationCreateWbMessageArgs = {
  input: CreateWbMessageInput;
};


export type MutationCreateWbReportArgs = {
  input: CreateWbReportInput;
};


export type MutationCreateWbReportFileArgs = {
  input: CreateWbReportFileInput;
};


export type MutationCriiptoVerifyArgs = {
  input: CriiptoVerifyInput;
};


export type MutationDeleteAccountArgs = {
  input: DeleteAccountInput;
};


export type MutationDeleteIdentityArgs = {
  input: DeleteIdentityInput;
};


export type MutationDeleteWbReportArgs = {
  input: DeleteWbReportInput;
};


export type MutationDestroyAccountLogoArgs = {
  input: DestroyAccountLogoInput;
};


export type MutationDestroyAccountSymbolArgs = {
  input: DestroyAccountSymbolInput;
};


export type MutationDestroyBankAccountArgs = {
  input: DestroyBankAccountInput;
};


export type MutationDestroyCheckListArgs = {
  input: DestroyCheckListInput;
};


export type MutationDestroyCheckRequestFileArgs = {
  input: DestroyCheckRequestFileInput;
};


export type MutationDestroyDepartmentArgs = {
  input: DestroyDepartmentInput;
};


export type MutationDestroyDocumentFileArgs = {
  input: DestroyDocumentFileInput;
};


export type MutationDestroyDocumentsArgs = {
  input: DestroyDocumentsInput;
};


export type MutationDestroyInvoiceArgs = {
  input: DestroyInvoiceInput;
};


export type MutationDestroyInvoiceLineArgs = {
  input: DestroyInvoiceLineInput;
};


export type MutationDestroyPaymentMethodArgs = {
  input: DestroyPaymentMethodInput;
};


export type MutationDestroyPeopleArgs = {
  input: DestroyPeopleInput;
};


export type MutationDestroyPolicyDocumentArgs = {
  input: DestroyPolicyDocumentInput;
};


export type MutationDestroyReceiptArgs = {
  input: DestroyReceiptInput;
};


export type MutationDestroyRoutineGuidelineArgs = {
  input: DestroyRoutineGuidelineInput;
};


export type MutationDestroySignatureArgs = {
  input: DestroySignatureInput;
};


export type MutationDestroyTransferArgs = {
  input: DestroyTransferInput;
};


export type MutationDestroyWbHandlerArgs = {
  input: DestroyWbHandlerInput;
};


export type MutationDestroyWbReportFileArgs = {
  input: DestroyWbReportFileInput;
};


export type MutationDuplicateInvoiceArgs = {
  input: DuplicateInvoiceInput;
};


export type MutationFindOrCreateJobApplicationArgs = {
  input: FindOrCreateJobApplicationInput;
};


export type MutationNotifyDocumentsArgs = {
  input: NotifyDocumentsInput;
};


export type MutationNotifySignatureArgs = {
  input: NotifySignatureInput;
};


export type MutationOnboardArgs = {
  input: OnboardInput;
};


export type MutationOverrideCheckArgs = {
  input: OverrideCheckInput;
};


export type MutationPrevalidateCheckFileArgs = {
  input: PrevalidateCheckFileInput;
};


export type MutationPublicCreateWbMessageArgs = {
  input: PublicCreateWbMessageInput;
};


export type MutationPublicUpdateWbReportArgs = {
  input: PublicUpdateWbReportInput;
};


export type MutationRefreshSessionOtpArgs = {
  input: RefreshSessionOtpInput;
};


export type MutationRefuseCheckArgs = {
  input: RefuseCheckInput;
};


export type MutationRefuseCheckListArgs = {
  input: RefuseCheckListInput;
};


export type MutationRefuseDocumentArgs = {
  input: RefuseDocumentInput;
};


export type MutationRegisterAccountArgs = {
  input: RegisterAccountInput;
};


export type MutationRegisterTeamAccountArgs = {
  input: RegisterTeamAccountInput;
};


export type MutationRejectJobApplicationArgs = {
  input: RejectJobApplicationInput;
};


export type MutationRemindCheckListsArgs = {
  input: RemindCheckListsInput;
};


export type MutationRemoveAccountMemberArgs = {
  input: RemoveAccountMemberInput;
};


export type MutationReopenWbReportArgs = {
  input: ReopenWbReportInput;
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type MutationResolveInvoiceArgs = {
  input: ResolveInvoiceInput;
};


export type MutationSendCreditNoteViaEhfArgs = {
  input: SendCreditNoteViaEhfInput;
};


export type MutationSendCreditNoteViaEmailArgs = {
  input: SendCreditNoteViaEmailInput;
};


export type MutationSendDocumentArgs = {
  input: SendDocumentInput;
};


export type MutationSendInvoiceViaEhfArgs = {
  input: SendInvoiceViaEhfInput;
};


export type MutationSendInvoiceViaEmailArgs = {
  input: SendInvoiceViaEmailInput;
};


export type MutationSendPasswordResetInstructionsArgs = {
  input: SendPasswordResetInstructionsInput;
};


export type MutationSendSmsConfirmationArgs = {
  input: SendSmsConfirmationInput;
};


export type MutationSendSupportMessageArgs = {
  input: SendSupportMessageInput;
};


export type MutationSendTeamWelcomeArgs = {
  input: SendTeamWelcomeInput;
};


export type MutationSetAccountOwnerArgs = {
  input: SetAccountOwnerInput;
};


export type MutationSetDefaultBankAccountArgs = {
  input: SetDefaultBankAccountInput;
};


export type MutationSetDefaultIdentityArgs = {
  input: SetDefaultIdentityInput;
};


export type MutationSetDefaultPaymentMethodArgs = {
  input: SetDefaultPaymentMethodInput;
};


export type MutationSetDefaultWbHandlerArgs = {
  input: SetDefaultWbHandlerInput;
};


export type MutationSetEnrollmentArgs = {
  input: SetEnrollmentInput;
};


export type MutationSetPeopleDepartmentsArgs = {
  input: SetPeopleDepartmentsInput;
};


export type MutationSetTeamRoleArgs = {
  input: SetTeamRoleInput;
};


export type MutationSignDocumentArgs = {
  input: SignDocumentInput;
};


export type MutationSubmitCheckArgs = {
  input: SubmitCheckInput;
};


export type MutationSubmitCheckRequestArgs = {
  input: SubmitCheckRequestInput;
};


export type MutationSubmitDocumentArgs = {
  input: SubmitDocumentInput;
};


export type MutationSubmitInvoiceArgs = {
  input: SubmitInvoiceInput;
};


export type MutationSubmitJobApplicationArgs = {
  input: SubmitJobApplicationInput;
};


export type MutationSubmitWbReportArgs = {
  input: SubmitWbReportInput;
};


export type MutationSwitchAccountArgs = {
  input: SwitchAccountInput;
};


export type MutationTerminateDocumentArgs = {
  input: TerminateDocumentInput;
};


export type MutationUnarchiveCheckListsArgs = {
  input: UnarchiveCheckListsInput;
};


export type MutationUnarchiveDocumentsArgs = {
  input: UnarchiveDocumentsInput;
};


export type MutationUnarchiveFreelanceProfileArgs = {
  input: UnarchiveFreelanceProfileInput;
};


export type MutationUnarchivePeopleArgs = {
  input: UnarchivePeopleInput;
};


export type MutationUpdateAccountArgs = {
  input: UpdateAccountInput;
};


export type MutationUpdateAccountMemberArgs = {
  input: UpdateAccountMemberInput;
};


export type MutationUpdateCheckListArgs = {
  input: UpdateCheckListInput;
};


export type MutationUpdateCheckRequestArgs = {
  input: UpdateCheckRequestInput;
};


export type MutationUpdateClientArgs = {
  input: UpdateClientInput;
};


export type MutationUpdateDepartmentArgs = {
  input: UpdateDepartmentInput;
};


export type MutationUpdateDocumentArgs = {
  input: UpdateDocumentInput;
};


export type MutationUpdateFreelanceProfileArgs = {
  input: UpdateFreelanceProfileInput;
};


export type MutationUpdateInvoiceArgs = {
  input: UpdateInvoiceInput;
};


export type MutationUpdateInvoiceLineArgs = {
  input: UpdateInvoiceLineInput;
};


export type MutationUpdateJobArgs = {
  input: UpdateJobInput;
};


export type MutationUpdateJobApplicationArgs = {
  input: UpdateJobApplicationInput;
};


export type MutationUpdatePaymentMethodArgs = {
  input: UpdatePaymentMethodInput;
};


export type MutationUpdateSubscriptionArgs = {
  input: UpdateSubscriptionInput;
};


export type MutationUpdateTransferArgs = {
  input: UpdateTransferInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationUpdateWbHandlerArgs = {
  input: UpdateWbHandlerInput;
};


export type MutationUpdateWbReportArgs = {
  input: UpdateWbReportInput;
};


export type MutationUploadCheckListsArgs = {
  input: UploadCheckListsInput;
};


export type MutationUploadCheckRequestFileArgs = {
  input: UploadCheckRequestFileInput;
};


export type MutationUploadSignaturesArgs = {
  input: UploadSignaturesInput;
};


export type MutationUseSessionOtpArgs = {
  input: UseSessionOtpInput;
};


export type MutationValidatePasswordResetTokenArgs = {
  input: ValidatePasswordResetTokenInput;
};


export type MutationVerifyPhoneNumberArgs = {
  input: VerifyPhoneNumberInput;
};

export type Name = {
  __typename?: 'Name';
  first: Scalars['String']['output'];
  full: Scalars['String']['output'];
  last: Scalars['String']['output'];
};

/** An object with an ID. */
export type Node = {
  /** ID of the object. */
  id: Scalars['ID']['output'];
};

/** Autogenerated input type of NotifyDocuments */
export type NotifyDocumentsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<DocumentsFilter>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Autogenerated return type of NotifyDocuments. */
export type NotifyDocumentsPayload = {
  __typename?: 'NotifyDocumentsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  documents?: Maybe<Array<Document>>;
  errors: Array<Error>;
};

/** Autogenerated input type of NotifySignature */
export type NotifySignatureInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of NotifySignature. */
export type NotifySignaturePayload = {
  __typename?: 'NotifySignaturePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  signature?: Maybe<Signature>;
};

export type OccupationCode = Node & {
  __typename?: 'OccupationCode';
  code: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  parentCode?: Maybe<Scalars['String']['output']>;
  shortName?: Maybe<Scalars['String']['output']>;
  tagList?: Maybe<Scalars['String']['output']>;
};

export type OccupationCodePagination = {
  __typename?: 'OccupationCodePagination';
  exportPath?: Maybe<Scalars['String']['output']>;
  items: Array<OccupationCode>;
  total: Scalars['Int']['output'];
};

/** Autogenerated input type of Onboard */
export type OnboardInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of Onboard. */
export type OnboardPayload = {
  __typename?: 'OnboardPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  onboarded: Scalars['Boolean']['output'];
};

export type OverrideCheckAttributes = {
  files?: InputMaybe<Array<Base64AttachmentAttributes>>;
  note?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of OverrideCheck */
export type OverrideCheckInput = {
  attributes?: InputMaybe<OverrideCheckAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of OverrideCheck. */
export type OverrideCheckPayload = {
  __typename?: 'OverrideCheckPayload';
  check?: Maybe<Check>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

export type PartnerColors = {
  __typename?: 'PartnerColors';
  contrast?: Maybe<Scalars['String']['output']>;
  default?: Maybe<Scalars['String']['output']>;
  hover?: Maybe<Scalars['String']['output']>;
};

export type PartnerColorsAttributes = {
  contrast?: InputMaybe<Scalars['String']['input']>;
  default?: InputMaybe<Scalars['String']['input']>;
  hover?: InputMaybe<Scalars['String']['input']>;
};

export type PaymentMethod = Node & {
  __typename?: 'PaymentMethod';
  address?: Maybe<Address>;
  cardBrand?: Maybe<Scalars['String']['output']>;
  cardExpMonth?: Maybe<Scalars['String']['output']>;
  cardExpYear?: Maybe<Scalars['Int']['output']>;
  cardLast4?: Maybe<Scalars['Int']['output']>;
  contactName?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  default: Scalars['Boolean']['output'];
  email?: Maybe<Scalars['String']['output']>;
  entityType: EntityTypeEnum;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  orderReference?: Maybe<Scalars['String']['output']>;
  organizationNumber?: Maybe<Scalars['String']['output']>;
  pending: Scalars['Boolean']['output'];
  type: PaymentMethodTypeEnum;
};

export type PaymentMethodPagination = {
  __typename?: 'PaymentMethodPagination';
  exportPath?: Maybe<Scalars['String']['output']>;
  items: Array<PaymentMethod>;
  total: Scalars['Int']['output'];
};

export enum PaymentMethodTypeEnum {
  Invoice = 'INVOICE',
  StripeCard = 'STRIPE_CARD'
}

export type PeopleFilter = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  departmentId?: InputMaybe<Scalars['ID']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  relation?: InputMaybe<PeopleRelationEnum>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export enum PeopleRelationEnum {
  Checks = 'CHECKS',
  Documents = 'DOCUMENTS'
}

export enum PeopleSortByEnum {
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  PhoneAsc = 'PHONE_ASC',
  PhoneDesc = 'PHONE_DESC'
}

export type Person = Node & {
  __typename?: 'Person';
  archivedAt?: Maybe<Scalars['String']['output']>;
  checkListCount: Scalars['Int']['output'];
  checkLists: Array<CheckList>;
  departments: Array<Department>;
  documentCount: Scalars['Int']['output'];
  documents: Array<Document>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  invitationName: Name;
  legalName?: Maybe<Name>;
  personalAccount: Account;
  phone?: Maybe<Scalars['String']['output']>;
  preferredName?: Maybe<Name>;
  teamMember: Scalars['Boolean']['output'];
  teamRole?: Maybe<TeamRoleEnum>;
};

export type PersonPagination = {
  __typename?: 'PersonPagination';
  exportPath?: Maybe<Scalars['String']['output']>;
  items: Array<Person>;
  total: Scalars['Int']['output'];
};

export type PhoneNumber = Node & {
  __typename?: 'PhoneNumber';
  countryCode: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  number: Scalars['String']['output'];
  tokenSentAt?: Maybe<Scalars['String']['output']>;
  tokenVerifiedAt?: Maybe<Scalars['String']['output']>;
};

export type PhoneNumberPagination = {
  __typename?: 'PhoneNumberPagination';
  exportPath?: Maybe<Scalars['String']['output']>;
  items: Array<PhoneNumber>;
  total: Scalars['Int']['output'];
};

export type PhoneNumberStruct = {
  __typename?: 'PhoneNumberStruct';
  countryCode?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['String']['output']>;
};

export type PhoneNumberStructAttributes = {
  countryCode?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of PrevalidateCheckFile */
export type PrevalidateCheckFileInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  file: Base64AttachmentAttributes;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of PrevalidateCheckFile. */
export type PrevalidateCheckFilePayload = {
  __typename?: 'PrevalidateCheckFilePayload';
  checkFileDocumentType?: Maybe<CheckFileDocumentTypeEnum>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

export enum PriceCurrencyEnum {
  Dkk = 'DKK',
  Eur = 'EUR',
  Nok = 'NOK',
  Sek = 'SEK',
  Usd = 'USD'
}

export enum PriceIntervalEnum {
  Month = 'MONTH',
  OneTime = 'ONE_TIME',
  Year = 'YEAR'
}

export enum PricePlanEnum {
  HealthServiceYouthPluss = 'HEALTH_SERVICE_YOUTH_PLUSS',
  HealthServiceYouthPremium = 'HEALTH_SERVICE_YOUTH_PREMIUM',
  HealthServiceYouthPro = 'HEALTH_SERVICE_YOUTH_PRO',
  HealthServiceYouthStarter = 'HEALTH_SERVICE_YOUTH_STARTER',
  KindergartenAndSchoolPluss = 'KINDERGARTEN_AND_SCHOOL_PLUSS',
  KindergartenAndSchoolPremium = 'KINDERGARTEN_AND_SCHOOL_PREMIUM',
  KindergartenAndSchoolPro = 'KINDERGARTEN_AND_SCHOOL_PRO',
  KindergartenAndSchoolStarter = 'KINDERGARTEN_AND_SCHOOL_STARTER',
  KindergartenPluss = 'KINDERGARTEN_PLUSS',
  KindergartenPremium = 'KINDERGARTEN_PREMIUM',
  KindergartenPro = 'KINDERGARTEN_PRO',
  KindergartenStarter = 'KINDERGARTEN_STARTER',
  LanguageService = 'LANGUAGE_SERVICE',
  MarchingBandLarge = 'MARCHING_BAND_LARGE',
  MarchingBandMedium = 'MARCHING_BAND_MEDIUM',
  MarchingBandSmall = 'MARCHING_BAND_SMALL',
  Pluss = 'PLUSS',
  Premium = 'PREMIUM',
  Pro = 'PRO',
  ReligiousPluss = 'RELIGIOUS_PLUSS',
  ReligiousPremium = 'RELIGIOUS_PREMIUM',
  ReligiousPro = 'RELIGIOUS_PRO',
  ReligiousStarter = 'RELIGIOUS_STARTER',
  SportsPluss = 'SPORTS_PLUSS',
  SportsPremium = 'SPORTS_PREMIUM',
  SportsPro = 'SPORTS_PRO',
  SportsStarter = 'SPORTS_STARTER',
  Starter = 'STARTER',
  VolunteerPluss = 'VOLUNTEER_PLUSS',
  VolunteerPremium = 'VOLUNTEER_PREMIUM',
  VolunteerPro = 'VOLUNTEER_PRO',
  VolunteerStarter = 'VOLUNTEER_STARTER'
}

export type Product = {
  __typename?: 'Product';
  amount: Scalars['Int']['output'];
  currency: PriceCurrencyEnum;
  limit?: Maybe<Scalars['Int']['output']>;
  type: ProductTypeEnum;
  usage?: Maybe<Scalars['Int']['output']>;
};

export type ProductMap = {
  __typename?: 'ProductMap';
  docuCheck: Product;
  eSignature: Product;
  nordicIdCheck: Product;
  norskPolitiattest: Product;
};

export enum ProductTypeEnum {
  DocuCheck = 'DOCU_CHECK',
  ESignature = 'E_SIGNATURE',
  NordicIdCheck = 'NORDIC_ID_CHECK',
  NorskPolitiattest = 'NORSK_POLITIATTEST'
}

/** Autogenerated input type of PublicCreateWbMessage */
export type PublicCreateWbMessageInput = {
  accessCode: Scalars['String']['input'];
  attributes: WbMessageAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  reportNumber: Scalars['String']['input'];
  wbSlug: Scalars['String']['input'];
};

/** Autogenerated return type of PublicCreateWbMessage. */
export type PublicCreateWbMessagePayload = {
  __typename?: 'PublicCreateWbMessagePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  wbReport?: Maybe<PublicWbReport>;
};

/** Autogenerated input type of PublicUpdateWbReport */
export type PublicUpdateWbReportInput = {
  attributes: PublicWbReportAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of PublicUpdateWbReport. */
export type PublicUpdateWbReportPayload = {
  __typename?: 'PublicUpdateWbReportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  wbReport?: Maybe<PublicWbReport>;
};

export type PublicWbAccount = {
  __typename?: 'PublicWbAccount';
  logo?: Maybe<Attachment>;
  name?: Maybe<Scalars['String']['output']>;
  routineGuidelines: Array<Attachment>;
  symbolDisplayPath?: Maybe<Scalars['String']['output']>;
  theme: AccountTheme;
  wbHandlers: Array<PublicWbHandler>;
  wbSettings: WbSettings;
  wbSlug: Scalars['String']['output'];
};

export type PublicWbHandler = {
  __typename?: 'PublicWbHandler';
  default: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type PublicWbReport = {
  __typename?: 'PublicWbReport';
  abandoned: Scalars['Boolean']['output'];
  accessCode: Scalars['String']['output'];
  account: PublicWbAccount;
  action?: Maybe<Scalars['String']['output']>;
  category?: Maybe<WbReportCategoryEnum>;
  closedAt?: Maybe<Scalars['String']['output']>;
  closedBy?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['String']['output']>;
  deletedBy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  downloadPath?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  files: Array<Attachment>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastInteractionAt: Scalars['String']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  people?: Maybe<Scalars['String']['output']>;
  phoneNumber: PhoneNumberStruct;
  privacy: WbReportPrivacyEnum;
  reportNumber?: Maybe<Scalars['String']['output']>;
  routineGuidelines: Array<Attachment>;
  status: WbReportStatusEnum;
  submittedAt?: Maybe<Scalars['String']['output']>;
  timing?: Maybe<Scalars['String']['output']>;
  type?: Maybe<WbReportTypeEnum>;
  wbHandlers: Array<PublicWbHandler>;
  wbMessages: Array<WbMessage>;
};

export type PublicWbReportAttributes = {
  action?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<WbReportCategoryEnum>;
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['EmailField']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  people?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<PhoneNumberStructAttributes>;
  privacy?: InputMaybe<WbReportPrivacyEnum>;
  timing?: InputMaybe<Scalars['String']['input']>;
  wbHandlerIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type Query = {
  __typename?: 'Query';
  checkSlug: Scalars['Boolean']['output'];
  clients: ClientPagination;
  companies: ListCompanyPagination;
  company?: Maybe<Company>;
  crcPurposes: CrcPurposePagination;
  creditNotes: CreditNotePagination;
  departments: DepartmentPagination;
  documents: DocumentPagination;
  freelanceProfileLookup: FreelanceProfileLookup;
  freelanceProfiles: FreelanceProfilePagination;
  invoices: InvoicePagination;
  jobApplications: JobApplicationPagination;
  jobChecks: JobCheckPagination;
  jobs: JobPagination;
  lastUsedPhoneNumber?: Maybe<PhoneNumber>;
  members: AccountPagination;
  /** Fetches an object given its ID. */
  node?: Maybe<Node>;
  occupationCodes: OccupationCodePagination;
  partnerJobApplications: JobApplicationPagination;
  partnerJobs: JobPagination;
  paymentMethods: PaymentMethodPagination;
  people: PersonPagination;
  phoneNumbers: PhoneNumberPagination;
  publicDocument?: Maybe<Document>;
  publicSignature?: Maybe<Signature>;
  purchases: InvoicePagination;
  receivedCheckLists: CheckListPagination;
  refunds: CreditNotePagination;
  relatedTeams: AccountPagination;
  salaryReports: SalaryReportPagination;
  salarySlips: SalarySlipPagination;
  sentCheckLists: CheckListPagination;
  session?: Maybe<Session>;
  suppliers: FreelanceProfilePagination;
  tier?: Maybe<Tier>;
  tiers: TierPagination;
  wbAccount?: Maybe<PublicWbAccount>;
  wbHandlers: WbHandlerPagination;
  wbReport?: Maybe<PublicWbReport>;
  wbReports: WbReportPagination;
};


export type QueryCheckSlugArgs = {
  slug: Scalars['String']['input'];
};


export type QueryClientsArgs = {
  filter?: InputMaybe<ClientsFilter>;
  freelanceProfileId: Scalars['ID']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<ClientsSortByEnum>;
};


export type QueryCompaniesArgs = {
  includeSubunits?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCompanyArgs = {
  orgNumber?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCrcPurposesArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCreditNotesArgs = {
  filter?: InputMaybe<CreditNotesFilter>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<CreditNotesSortByEnum>;
};


export type QueryDepartmentsArgs = {
  filter?: InputMaybe<DepartmentsFilter>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryDocumentsArgs = {
  filter?: InputMaybe<DocumentsFilter>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFreelanceProfileLookupArgs = {
  jobApplicationId: Scalars['ID']['input'];
  organizationNumber: Scalars['String']['input'];
};


export type QueryFreelanceProfilesArgs = {
  filter?: InputMaybe<FreelanceProfilesFilter>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<FreelanceProfilesSortByEnum>;
};


export type QueryInvoicesArgs = {
  filter?: InputMaybe<InvoicesFilter>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<InvoicesSortByEnum>;
};


export type QueryJobApplicationsArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryJobChecksArgs = {
  jobCheckType?: InputMaybe<JobCheckTypeEnum>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryJobsArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMembersArgs = {
  filter?: InputMaybe<MembersFilter>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryNodeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOccupationCodesArgs = {
  keyword?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPartnerJobApplicationsArgs = {
  filter?: InputMaybe<JobApplicationsFilter>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPartnerJobsArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPaymentMethodsArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPeopleArgs = {
  filter?: InputMaybe<PeopleFilter>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<PeopleSortByEnum>;
};


export type QueryPhoneNumbersArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPublicDocumentArgs = {
  token: Scalars['String']['input'];
};


export type QueryPublicSignatureArgs = {
  token: Scalars['String']['input'];
};


export type QueryPurchasesArgs = {
  filter?: InputMaybe<InvoicesFilter>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<InvoicesSortByEnum>;
};


export type QueryReceivedCheckListsArgs = {
  filter?: InputMaybe<CheckListsFilter>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryRefundsArgs = {
  filter?: InputMaybe<CreditNotesFilter>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<CreditNotesSortByEnum>;
};


export type QueryRelatedTeamsArgs = {
  filter?: InputMaybe<RelatedTeamsFilter>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySalaryReportsArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySalarySlipsArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySentCheckListsArgs = {
  filter?: InputMaybe<CheckListsFilter>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<CheckListsSortByEnum>;
};


export type QuerySuppliersArgs = {
  filter?: InputMaybe<FreelanceProfilesFilter>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<FreelanceProfilesSortByEnum>;
};


export type QueryTierArgs = {
  id: Scalars['String']['input'];
};


export type QueryTiersArgs = {
  filter?: InputMaybe<TiersFilter>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryWbAccountArgs = {
  wbSlug: Scalars['String']['input'];
};


export type QueryWbHandlersArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryWbReportArgs = {
  accessCode: Scalars['String']['input'];
  reportNumber?: InputMaybe<Scalars['String']['input']>;
  wbSlug: Scalars['String']['input'];
};


export type QueryWbReportsArgs = {
  filter?: InputMaybe<WbReportsFilter>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<WbReportsSortByEnum>;
};

export type RangeAttributes = {
  from: Scalars['Int']['input'];
  to: Scalars['Int']['input'];
};

export type Receipt = Node & {
  __typename?: 'Receipt';
  file: Attachment;
  id: Scalars['ID']['output'];
};

export type ReceiptAttributes = {
  file: Base64AttachmentAttributes;
};

export type ReferencePerson = {
  __typename?: 'ReferencePerson';
  email: Scalars['String']['output'];
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  referenceNumber?: Maybe<Scalars['String']['output']>;
};

export type ReferencePersonAttributes = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  referenceNumber?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of RefreshSessionOtp */
export type RefreshSessionOtpInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  otp: Scalars['String']['input'];
};

/** Autogenerated return type of RefreshSessionOtp. */
export type RefreshSessionOtpPayload = {
  __typename?: 'RefreshSessionOtpPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated input type of RefuseCheck */
export type RefuseCheckInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  refuseReason: Scalars['String']['input'];
};

/** Autogenerated input type of RefuseCheckList */
export type RefuseCheckListInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  refuseReason: Scalars['String']['input'];
};

/** Autogenerated return type of RefuseCheckList. */
export type RefuseCheckListPayload = {
  __typename?: 'RefuseCheckListPayload';
  checkList?: Maybe<CheckList>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated return type of RefuseCheck. */
export type RefuseCheckPayload = {
  __typename?: 'RefuseCheckPayload';
  check?: Maybe<Check>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated input type of RefuseDocument */
export type RefuseDocumentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of RefuseDocument. */
export type RefuseDocumentPayload = {
  __typename?: 'RefuseDocumentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  document?: Maybe<Document>;
  errors: Array<Error>;
};

export type RegisterAccountAttributes = {
  email: Scalars['EmailField']['input'];
  language?: InputMaybe<LanguageEnum>;
  referrer?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of RegisterAccount */
export type RegisterAccountInput = {
  attributes: RegisterAccountAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  partnerAccountId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of RegisterAccount. */
export type RegisterAccountPayload = {
  __typename?: 'RegisterAccountPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

export type RegisterTeamAccountAttributes = {
  address?: InputMaybe<AddressAttributes>;
  country: Scalars['String']['input'];
  email: Scalars['EmailField']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<LanguageEnum>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  marketingConsent: Scalars['Boolean']['input'];
  necessaryCookiesConsent?: InputMaybe<Scalars['Boolean']['input']>;
  nonProfit?: InputMaybe<Scalars['Boolean']['input']>;
  organizationName?: InputMaybe<Scalars['String']['input']>;
  organizationNumber: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  pricePlan?: InputMaybe<PricePlanEnum>;
  tag?: InputMaybe<AccountTagEnum>;
  tierId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of RegisterTeamAccount */
export type RegisterTeamAccountInput = {
  attributes: RegisterTeamAccountAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of RegisterTeamAccount. */
export type RegisterTeamAccountPayload = {
  __typename?: 'RegisterTeamAccountPayload';
  account?: Maybe<Account>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated input type of RejectJobApplication */
export type RejectJobApplicationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of RejectJobApplication. */
export type RejectJobApplicationPayload = {
  __typename?: 'RejectJobApplicationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  jobApplication?: Maybe<JobApplication>;
};

export type RelatedTeamsFilter = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of RemindCheckLists */
export type RemindCheckListsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CheckListsFilter>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Autogenerated return type of RemindCheckLists. */
export type RemindCheckListsPayload = {
  __typename?: 'RemindCheckListsPayload';
  checkLists?: Maybe<Array<CheckList>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated input type of RemoveAccountMember */
export type RemoveAccountMemberInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  memberId: Scalars['ID']['input'];
};

/** Autogenerated return type of RemoveAccountMember. */
export type RemoveAccountMemberPayload = {
  __typename?: 'RemoveAccountMemberPayload';
  account?: Maybe<Account>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated input type of ReopenWbReport */
export type ReopenWbReportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of ReopenWbReport. */
export type ReopenWbReportPayload = {
  __typename?: 'ReopenWbReportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  wbReport?: Maybe<WbReport>;
};

/** Autogenerated input type of ResetPassword */
export type ResetPasswordInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['EmailField']['input'];
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

/** Autogenerated return type of ResetPassword. */
export type ResetPasswordPayload = {
  __typename?: 'ResetPasswordPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated input type of ResolveInvoice */
export type ResolveInvoiceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of ResolveInvoice. */
export type ResolveInvoicePayload = {
  __typename?: 'ResolveInvoicePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  invoice?: Maybe<Invoice>;
};

export type SalaryReport = Node & {
  __typename?: 'SalaryReport';
  id: Scalars['ID']['output'];
  payoutAmount: Scalars['Int']['output'];
  pdfDocument?: Maybe<Attachment>;
  salaryTaxAmount: Scalars['Int']['output'];
  year: Scalars['Int']['output'];
};

export type SalaryReportPagination = {
  __typename?: 'SalaryReportPagination';
  exportPath?: Maybe<Scalars['String']['output']>;
  items: Array<SalaryReport>;
  total: Scalars['Int']['output'];
};

export type SalarySlip = Node & {
  __typename?: 'SalarySlip';
  id: Scalars['ID']['output'];
  payoutAmount: Scalars['Int']['output'];
  payoutDate: Scalars['String']['output'];
  pdfDocument?: Maybe<Attachment>;
  salaryTax: Scalars['Float']['output'];
  salaryTaxAmount: Scalars['Int']['output'];
};

export type SalarySlipPagination = {
  __typename?: 'SalarySlipPagination';
  exportPath?: Maybe<Scalars['String']['output']>;
  items: Array<SalarySlip>;
  total: Scalars['Int']['output'];
};

/** Autogenerated input type of SendCreditNoteViaEhf */
export type SendCreditNoteViaEhfInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of SendCreditNoteViaEhf. */
export type SendCreditNoteViaEhfPayload = {
  __typename?: 'SendCreditNoteViaEhfPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated input type of SendCreditNoteViaEmail */
export type SendCreditNoteViaEmailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of SendCreditNoteViaEmail. */
export type SendCreditNoteViaEmailPayload = {
  __typename?: 'SendCreditNoteViaEmailPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated input type of SendDocument */
export type SendDocumentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of SendDocument. */
export type SendDocumentPayload = {
  __typename?: 'SendDocumentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  document?: Maybe<Document>;
  errors: Array<Error>;
};

/** Autogenerated input type of SendInvoiceViaEhf */
export type SendInvoiceViaEhfInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of SendInvoiceViaEhf. */
export type SendInvoiceViaEhfPayload = {
  __typename?: 'SendInvoiceViaEhfPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated input type of SendInvoiceViaEmail */
export type SendInvoiceViaEmailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of SendInvoiceViaEmail. */
export type SendInvoiceViaEmailPayload = {
  __typename?: 'SendInvoiceViaEmailPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated input type of SendPasswordResetInstructions */
export type SendPasswordResetInstructionsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['EmailField']['input'];
};

/** Autogenerated return type of SendPasswordResetInstructions. */
export type SendPasswordResetInstructionsPayload = {
  __typename?: 'SendPasswordResetInstructionsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated input type of SendSmsConfirmation */
export type SendSmsConfirmationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  countryCode: Scalars['String']['input'];
  number: Scalars['String']['input'];
};

/** Autogenerated return type of SendSmsConfirmation. */
export type SendSmsConfirmationPayload = {
  __typename?: 'SendSmsConfirmationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated input type of SendSupportMessage */
export type SendSupportMessageInput = {
  clientId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  freelanceProfileId: Scalars['ID']['input'];
  message: Scalars['String']['input'];
};

/** Autogenerated return type of SendSupportMessage. */
export type SendSupportMessagePayload = {
  __typename?: 'SendSupportMessagePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated input type of SendTeamWelcome */
export type SendTeamWelcomeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of SendTeamWelcome. */
export type SendTeamWelcomePayload = {
  __typename?: 'SendTeamWelcomePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

export type Session = {
  __typename?: 'Session';
  account: Account;
  accounts: Array<Account>;
  administrator?: Maybe<Scalars['String']['output']>;
  hasFreelanceProfiles: Scalars['Boolean']['output'];
  hasIdentity: Scalars['Boolean']['output'];
  hasIndividualFreelanceProfile: Scalars['Boolean']['output'];
  needsOnboarding: Scalars['Boolean']['output'];
  phoneNumber?: Maybe<PhoneNumber>;
  role?: Maybe<TeamRoleEnum>;
  user: User;
};

/** Autogenerated input type of SetAccountOwner */
export type SetAccountOwnerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  memberId: Scalars['ID']['input'];
};

/** Autogenerated return type of SetAccountOwner. */
export type SetAccountOwnerPayload = {
  __typename?: 'SetAccountOwnerPayload';
  account?: Maybe<Account>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated input type of SetDefaultBankAccount */
export type SetDefaultBankAccountInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of SetDefaultBankAccount. */
export type SetDefaultBankAccountPayload = {
  __typename?: 'SetDefaultBankAccountPayload';
  bankAccount?: Maybe<BankAccount>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated input type of SetDefaultIdentity */
export type SetDefaultIdentityInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of SetDefaultIdentity. */
export type SetDefaultIdentityPayload = {
  __typename?: 'SetDefaultIdentityPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  identity?: Maybe<Identity>;
};

/** Autogenerated input type of SetDefaultPaymentMethod */
export type SetDefaultPaymentMethodInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of SetDefaultPaymentMethod. */
export type SetDefaultPaymentMethodPayload = {
  __typename?: 'SetDefaultPaymentMethodPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  paymentMethod?: Maybe<PaymentMethod>;
};

/** Autogenerated input type of SetDefaultWbHandler */
export type SetDefaultWbHandlerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of SetDefaultWbHandler. */
export type SetDefaultWbHandlerPayload = {
  __typename?: 'SetDefaultWbHandlerPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  wbHandler?: Maybe<WbHandler>;
};

/** Autogenerated input type of SetEnrollment */
export type SetEnrollmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  freelanceProfileId: Scalars['ID']['input'];
  partnerAccountId: Scalars['ID']['input'];
};

/** Autogenerated return type of SetEnrollment. */
export type SetEnrollmentPayload = {
  __typename?: 'SetEnrollmentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  enrollment?: Maybe<Enrollment>;
  errors: Array<Error>;
};

/** Autogenerated input type of SetPeopleDepartments */
export type SetPeopleDepartmentsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  departmentIds: Array<Scalars['ID']['input']>;
  filter?: InputMaybe<PeopleFilter>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Autogenerated return type of SetPeopleDepartments. */
export type SetPeopleDepartmentsPayload = {
  __typename?: 'SetPeopleDepartmentsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  people?: Maybe<Array<Person>>;
};

/** Autogenerated input type of SetTeamRole */
export type SetTeamRoleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  memberId: Scalars['ID']['input'];
  role: TeamRoleEnum;
};

/** Autogenerated return type of SetTeamRole. */
export type SetTeamRolePayload = {
  __typename?: 'SetTeamRolePayload';
  account?: Maybe<Account>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

export type SignDocumentAttributes = {
  digitalSignature?: InputMaybe<Base64AttachmentAttributes>;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of SignDocument */
export type SignDocumentInput = {
  attributes: SignDocumentAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of SignDocument. */
export type SignDocumentPayload = {
  __typename?: 'SignDocumentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  document?: Maybe<Document>;
  errors: Array<Error>;
};

export type Signature = Node & {
  __typename?: 'Signature';
  account?: Maybe<Account>;
  archivedAt?: Maybe<Scalars['String']['output']>;
  bulk: Scalars['Boolean']['output'];
  canSign: Scalars['Boolean']['output'];
  clientName?: Maybe<Scalars['String']['output']>;
  designation?: Maybe<Scalars['String']['output']>;
  document: Document;
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Name;
  notifiedAt?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  refusedAt?: Maybe<Scalars['String']['output']>;
  signUrl?: Maybe<Scalars['String']['output']>;
  signatureNumber: Scalars['Int']['output'];
  signedAt?: Maybe<Scalars['String']['output']>;
  status: SignatureStatusEnum;
};

export type SignatureAttributes = {
  designation?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};

export enum SignatureStatusEnum {
  Pending = 'PENDING',
  Refused = 'REFUSED',
  Signed = 'SIGNED'
}

export type StripeCardAttributes = {
  default?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  entityType: EntityTypeEnum;
  stripeSetupIntentId: Scalars['String']['input'];
};

export type StripeSetupIntent = {
  __typename?: 'StripeSetupIntent';
  clientSecret: Scalars['String']['output'];
};

/** Autogenerated input type of SubmitCheck */
export type SubmitCheckInput = {
  attributes?: InputMaybe<CheckAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of SubmitCheck. */
export type SubmitCheckPayload = {
  __typename?: 'SubmitCheckPayload';
  check?: Maybe<Check>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated input type of SubmitCheckRequest */
export type SubmitCheckRequestInput = {
  attributes: CheckRequestAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of SubmitCheckRequest. */
export type SubmitCheckRequestPayload = {
  __typename?: 'SubmitCheckRequestPayload';
  checkRequest?: Maybe<CheckRequest>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated input type of SubmitDocument */
export type SubmitDocumentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of SubmitDocument. */
export type SubmitDocumentPayload = {
  __typename?: 'SubmitDocumentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  document?: Maybe<Document>;
  errors: Array<Error>;
};

/** Autogenerated input type of SubmitInvoice */
export type SubmitInvoiceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of SubmitInvoice. */
export type SubmitInvoicePayload = {
  __typename?: 'SubmitInvoicePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  invoice?: Maybe<Invoice>;
};

/** Autogenerated input type of SubmitJobApplication */
export type SubmitJobApplicationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of SubmitJobApplication. */
export type SubmitJobApplicationPayload = {
  __typename?: 'SubmitJobApplicationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  jobApplication?: Maybe<JobApplication>;
};

/** Autogenerated input type of SubmitWbReport */
export type SubmitWbReportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of SubmitWbReport. */
export type SubmitWbReportPayload = {
  __typename?: 'SubmitWbReportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  wbReport?: Maybe<PublicWbReport>;
};

export type Subscription = {
  __typename?: 'Subscription';
  currentPeriodEndAt: Scalars['String']['output'];
  currentPeriodStartAt: Scalars['String']['output'];
  downgradeTier?: Maybe<Tier>;
  downgradedAt?: Maybe<Scalars['String']['output']>;
  tier: Tier;
};

/** Autogenerated input type of SwitchAccount */
export type SwitchAccountInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of SwitchAccount. */
export type SwitchAccountPayload = {
  __typename?: 'SwitchAccountPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  token?: Maybe<Scalars['String']['output']>;
};

export type TaskReport = WorkReport & {
  __typename?: 'TaskReport';
  endDate: Scalars['String']['output'];
  hours: Scalars['Float']['output'];
  startDate: Scalars['String']['output'];
  type: WorkReportTypeEnum;
};

export enum TeamRoleEnum {
  Admin = 'ADMIN',
  Member = 'MEMBER',
  Owner = 'OWNER'
}

export type TerminateDocumentAttributes = {
  terminatedCopyRecipients?: InputMaybe<Array<Scalars['String']['input']>>;
  terminatedOn: Scalars['String']['input'];
  terminationReason: Scalars['String']['input'];
};

/** Autogenerated input type of TerminateDocument */
export type TerminateDocumentInput = {
  attributes: TerminateDocumentAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of TerminateDocument. */
export type TerminateDocumentPayload = {
  __typename?: 'TerminateDocumentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  document?: Maybe<Document>;
  errors: Array<Error>;
};

export type Tier = {
  __typename?: 'Tier';
  amount: Scalars['Int']['output'];
  currency: PriceCurrencyEnum;
  features: Array<FeatureTypeEnum>;
  id: Scalars['String']['output'];
  interval: PriceIntervalEnum;
  memberLimit?: Maybe<Scalars['Int']['output']>;
  memberUsage?: Maybe<Scalars['Int']['output']>;
  products: ProductMap;
  type: TierTypeEnum;
};

export type TierPagination = {
  __typename?: 'TierPagination';
  exportPath?: Maybe<Scalars['String']['output']>;
  items: Array<Tier>;
  total: Scalars['Int']['output'];
};

export enum TierTypeEnum {
  Basic = 'BASIC',
  Enterprise = 'ENTERPRISE',
  Plus = 'PLUS',
  Premium = 'PREMIUM'
}

export type TiersFilter = {
  currency?: InputMaybe<PriceCurrencyEnum>;
  interval?: InputMaybe<PriceIntervalEnum>;
};

export type TimeReport = WorkReport & {
  __typename?: 'TimeReport';
  endDate: Scalars['String']['output'];
  endTime: Scalars['String']['output'];
  startDate: Scalars['String']['output'];
  startTime: Scalars['String']['output'];
  type: WorkReportTypeEnum;
};

export type Transfer = Node & {
  __typename?: 'Transfer';
  amount: Scalars['Int']['output'];
  currency: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  fromFinanceAccount?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  invoice: Invoice;
  toFinanceAccount?: Maybe<Scalars['String']['output']>;
};

export type UiFlags = {
  __typename?: 'UiFlags';
  hasReceivedCheckLists?: Maybe<Scalars['Boolean']['output']>;
  hasSentCheckLists?: Maybe<Scalars['Boolean']['output']>;
  shownIds: Array<Scalars['String']['output']>;
};

export type UiFlagsAttributes = {
  shownIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Autogenerated input type of UnarchiveCheckLists */
export type UnarchiveCheckListsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CheckListsFilter>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Autogenerated return type of UnarchiveCheckLists. */
export type UnarchiveCheckListsPayload = {
  __typename?: 'UnarchiveCheckListsPayload';
  checkLists?: Maybe<Array<CheckList>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated input type of UnarchiveDocuments */
export type UnarchiveDocumentsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<DocumentsFilter>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Autogenerated return type of UnarchiveDocuments. */
export type UnarchiveDocumentsPayload = {
  __typename?: 'UnarchiveDocumentsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  documents?: Maybe<Array<Document>>;
  errors: Array<Error>;
};

/** Autogenerated input type of UnarchiveFreelanceProfile */
export type UnarchiveFreelanceProfileInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UnarchiveFreelanceProfile. */
export type UnarchiveFreelanceProfilePayload = {
  __typename?: 'UnarchiveFreelanceProfilePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  freelanceProfile: FreelanceProfile;
};

/** Autogenerated input type of UnarchivePeople */
export type UnarchivePeopleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PeopleFilter>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Autogenerated return type of UnarchivePeople. */
export type UnarchivePeoplePayload = {
  __typename?: 'UnarchivePeoplePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  people?: Maybe<Array<Person>>;
};

export type UpdateAccountAttributes = {
  address?: InputMaybe<AddressAttributes>;
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  industrySector?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<LanguageEnum>;
  logo?: InputMaybe<Base64AttachmentAttributes>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  symbol?: InputMaybe<Base64AttachmentAttributes>;
  theme?: InputMaybe<AccountThemeAttributes>;
  uiFlags?: InputMaybe<UiFlagsAttributes>;
  wbSettings?: InputMaybe<WbSettingsAttributes>;
  webhookUrl?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of UpdateAccount */
export type UpdateAccountInput = {
  attributes: UpdateAccountAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  personal?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated input type of UpdateAccountMember */
export type UpdateAccountMemberInput = {
  attributes: UpdateMemberAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  memberId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateAccountMember. */
export type UpdateAccountMemberPayload = {
  __typename?: 'UpdateAccountMemberPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  member?: Maybe<Account>;
};

/** Autogenerated return type of UpdateAccount. */
export type UpdateAccountPayload = {
  __typename?: 'UpdateAccountPayload';
  account?: Maybe<Account>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

export type UpdateCheckListAttributes = {
  jobDescription?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of UpdateCheckList */
export type UpdateCheckListInput = {
  attributes: UpdateCheckListAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateCheckList. */
export type UpdateCheckListPayload = {
  __typename?: 'UpdateCheckListPayload';
  checkList?: Maybe<CheckList>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated input type of UpdateCheckRequest */
export type UpdateCheckRequestInput = {
  attributes: CheckRequestAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateCheckRequest. */
export type UpdateCheckRequestPayload = {
  __typename?: 'UpdateCheckRequestPayload';
  checkRequest?: Maybe<CheckRequest>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

export type UpdateClientAttributes = {
  address?: InputMaybe<AddressAttributes>;
  clientNumber?: InputMaybe<Scalars['Int']['input']>;
  clientType?: InputMaybe<ClientTypeEnum>;
  email?: InputMaybe<Scalars['String']['input']>;
  friendlyName?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nationalRegisterCheck?: InputMaybe<Scalars['Boolean']['input']>;
  orgNumber?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  preferredDeliveryMethod?: InputMaybe<ClientPreferredDeliveryMethodEnum>;
  referencePeople?: InputMaybe<Array<ReferencePersonAttributes>>;
};

/** Autogenerated input type of UpdateClient */
export type UpdateClientInput = {
  attributes: UpdateClientAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateClient. */
export type UpdateClientPayload = {
  __typename?: 'UpdateClientPayload';
  client?: Maybe<Client>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated input type of UpdateDepartment */
export type UpdateDepartmentInput = {
  attributes: DepartmentAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateDepartment. */
export type UpdateDepartmentPayload = {
  __typename?: 'UpdateDepartmentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  department?: Maybe<Department>;
  errors: Array<Error>;
};

/** Autogenerated input type of UpdateDocument */
export type UpdateDocumentInput = {
  attributes: DocumentAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateDocument. */
export type UpdateDocumentPayload = {
  __typename?: 'UpdateDocumentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  document?: Maybe<Document>;
  errors: Array<Error>;
};

export type UpdateFreelanceProfileAttributes = {
  address?: InputMaybe<AddressAttributes>;
  bankAccount?: InputMaybe<BankAccountAttributes>;
  invoiceLogo?: InputMaybe<Base64AttachmentAttributes>;
  invoiceStartingNumber?: InputMaybe<Scalars['Int']['input']>;
  journalClosedMonth?: InputMaybe<Scalars['String']['input']>;
  journalFormat?: InputMaybe<JournalFormatEnum>;
  referencePeople?: InputMaybe<Array<ReferencePersonAttributes>>;
};

/** Autogenerated input type of UpdateFreelanceProfile */
export type UpdateFreelanceProfileInput = {
  attributes: UpdateFreelanceProfileAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateFreelanceProfile. */
export type UpdateFreelanceProfilePayload = {
  __typename?: 'UpdateFreelanceProfilePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  freelanceProfile?: Maybe<FreelanceProfile>;
};

export type UpdateInvoiceAttributes = {
  bankAccountId?: InputMaybe<Scalars['ID']['input']>;
  buyerReference?: InputMaybe<Scalars['String']['input']>;
  clientId?: InputMaybe<Scalars['ID']['input']>;
  clientReferencePerson?: InputMaybe<ReferencePersonAttributes>;
  dueOn?: InputMaybe<Scalars['String']['input']>;
  freelanceProfileId?: InputMaybe<Scalars['ID']['input']>;
  invoicedOn?: InputMaybe<Scalars['String']['input']>;
  lines?: InputMaybe<Array<BulkInvoiceLineAttributes>>;
  orderReference?: InputMaybe<Scalars['String']['input']>;
  senderReferencePerson?: InputMaybe<ReferencePersonAttributes>;
};

/** Autogenerated input type of UpdateInvoice */
export type UpdateInvoiceInput = {
  attributes: UpdateInvoiceAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type UpdateInvoiceLineAttributes = {
  addressFrom?: InputMaybe<Scalars['String']['input']>;
  addressTo?: InputMaybe<Scalars['String']['input']>;
  collapsed?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  dietNonTaxableUnitPrice?: InputMaybe<Scalars['Int']['input']>;
  dietTaxableUnitPrice?: InputMaybe<Scalars['Int']['input']>;
  lineType?: InputMaybe<LineTypeEnum>;
  occupationCodeId?: InputMaybe<Scalars['ID']['input']>;
  quantity?: InputMaybe<Scalars['Float']['input']>;
  unitPrice?: InputMaybe<Scalars['Int']['input']>;
  vatRate?: InputMaybe<Scalars['Float']['input']>;
  workInvoiceLineId?: InputMaybe<Scalars['ID']['input']>;
  workReport?: InputMaybe<WorkReportAttributes>;
};

/** Autogenerated input type of UpdateInvoiceLine */
export type UpdateInvoiceLineInput = {
  attributes: UpdateInvoiceLineAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateInvoiceLine. */
export type UpdateInvoiceLinePayload = {
  __typename?: 'UpdateInvoiceLinePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  invoiceLine?: Maybe<InvoiceLine>;
};

/** Autogenerated return type of UpdateInvoice. */
export type UpdateInvoicePayload = {
  __typename?: 'UpdateInvoicePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  invoice?: Maybe<Invoice>;
};

export type UpdateJobApplicationAttributes = {
  applicationFormAnswers?: InputMaybe<Array<FormAnswerAttributes>>;
};

/** Autogenerated input type of UpdateJobApplication */
export type UpdateJobApplicationInput = {
  attributes: UpdateJobApplicationAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateJobApplication. */
export type UpdateJobApplicationPayload = {
  __typename?: 'UpdateJobApplicationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  jobApplication?: Maybe<JobApplication>;
};

export type UpdateJobAttributes = {
  address?: InputMaybe<AddressAttributes>;
  applicationDeadline?: InputMaybe<Scalars['String']['input']>;
  applicationForm?: InputMaybe<FormAttributes>;
  applicationLimit?: InputMaybe<Scalars['Int']['input']>;
  colors?: InputMaybe<PartnerColorsAttributes>;
  compensation?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  enrollmentCallback?: InputMaybe<Scalars['Boolean']['input']>;
  jobChecks?: InputMaybe<Array<JobCheckConfigAttributes>>;
  jobType?: InputMaybe<Scalars['String']['input']>;
  languageRequirements?: InputMaybe<Scalars['String']['input']>;
  logo?: InputMaybe<Base64AttachmentAttributes>;
  payout?: InputMaybe<Scalars['String']['input']>;
  privacyLink?: InputMaybe<Scalars['String']['input']>;
  tagline?: InputMaybe<Scalars['String']['input']>;
  termsLink?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of UpdateJob */
export type UpdateJobInput = {
  attributes: UpdateJobAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateJob. */
export type UpdateJobPayload = {
  __typename?: 'UpdateJobPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  job?: Maybe<Job>;
};

export type UpdateMemberAttributes = {
  departmentMemberships: Array<DepartmentMembershipAttributes>;
  role: TeamRoleEnum;
};

export type UpdatePaymentMethodAttributes = {
  address?: InputMaybe<AddressAttributes>;
  contactName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  orderReference?: InputMaybe<Scalars['String']['input']>;
  organizationNumber?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of UpdatePaymentMethod */
export type UpdatePaymentMethodInput = {
  attributes: UpdatePaymentMethodAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdatePaymentMethod. */
export type UpdatePaymentMethodPayload = {
  __typename?: 'UpdatePaymentMethodPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  paymentMethod?: Maybe<PaymentMethod>;
};

/** Autogenerated input type of UpdateSubscription */
export type UpdateSubscriptionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  tierId: Scalars['String']['input'];
};

/** Autogenerated return type of UpdateSubscription. */
export type UpdateSubscriptionPayload = {
  __typename?: 'UpdateSubscriptionPayload';
  account?: Maybe<Account>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

export type UpdateTransferAttributes = {
  amount?: InputMaybe<Scalars['Int']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  fromFinanceAccount?: InputMaybe<Scalars['String']['input']>;
  toFinanceAccount?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of UpdateTransfer */
export type UpdateTransferInput = {
  attributes: UpdateTransferAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateTransfer. */
export type UpdateTransferPayload = {
  __typename?: 'UpdateTransferPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  transfer?: Maybe<Transfer>;
};

export type UpdateUserAttributes = {
  marketingConsent?: InputMaybe<Scalars['Boolean']['input']>;
  necessaryCookiesConsent?: InputMaybe<Scalars['Boolean']['input']>;
  occupation?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  referrer?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of UpdateUser */
export type UpdateUserInput = {
  attributes: UpdateUserAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdateUser. */
export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  user?: Maybe<User>;
};

/** Autogenerated input type of UpdateWbHandler */
export type UpdateWbHandlerInput = {
  attributes: WbHandlerAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateWbHandler. */
export type UpdateWbHandlerPayload = {
  __typename?: 'UpdateWbHandlerPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  wbHandler?: Maybe<WbHandler>;
};

/** Autogenerated input type of UpdateWbReport */
export type UpdateWbReportInput = {
  attributes: WbReportAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateWbReport. */
export type UpdateWbReportPayload = {
  __typename?: 'UpdateWbReportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  wbReport?: Maybe<WbReport>;
};

/** Autogenerated input type of UploadCheckLists */
export type UploadCheckListsInput = {
  checkRequestId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  file: Base64AttachmentAttributes;
};

/** Autogenerated return type of UploadCheckLists. */
export type UploadCheckListsPayload = {
  __typename?: 'UploadCheckListsPayload';
  checkRequest?: Maybe<CheckRequest>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated input type of UploadCheckRequestFile */
export type UploadCheckRequestFileInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  file: Base64AttachmentAttributes;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UploadCheckRequestFile. */
export type UploadCheckRequestFilePayload = {
  __typename?: 'UploadCheckRequestFilePayload';
  checkRequest?: Maybe<CheckRequest>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated input type of UploadSignatures */
export type UploadSignaturesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  documentId: Scalars['ID']['input'];
  file: Base64AttachmentAttributes;
};

/** Autogenerated return type of UploadSignatures. */
export type UploadSignaturesPayload = {
  __typename?: 'UploadSignaturesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  document?: Maybe<Document>;
  errors: Array<Error>;
};

/** Autogenerated input type of UseSessionOtp */
export type UseSessionOtpInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  otp: Scalars['String']['input'];
};

/** Autogenerated return type of UseSessionOtp. */
export type UseSessionOtpPayload = {
  __typename?: 'UseSessionOtpPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deepLink?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  token?: Maybe<Scalars['String']['output']>;
};

export type User = Node & {
  __typename?: 'User';
  confirmed: Scalars['Boolean']['output'];
  email: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  language: LanguageEnum;
  lastName?: Maybe<Scalars['String']['output']>;
  marketingConsent: Scalars['Boolean']['output'];
  necessaryCookiesConsent: Scalars['Boolean']['output'];
  occupation?: Maybe<Scalars['String']['output']>;
  referrer?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of ValidatePasswordResetToken */
export type ValidatePasswordResetTokenInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['EmailField']['input'];
  token: Scalars['String']['input'];
};

/** Autogenerated return type of ValidatePasswordResetToken. */
export type ValidatePasswordResetTokenPayload = {
  __typename?: 'ValidatePasswordResetTokenPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated input type of VerifyPhoneNumber */
export type VerifyPhoneNumberInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  countryCode: Scalars['String']['input'];
  number: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

/** Autogenerated return type of VerifyPhoneNumber. */
export type VerifyPhoneNumberPayload = {
  __typename?: 'VerifyPhoneNumberPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

export type WbHandler = Node & {
  __typename?: 'WbHandler';
  account: Account;
  default: Scalars['Boolean']['output'];
  hasReports: Scalars['Boolean']['output'];
  /** ID of the object. */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type WbHandlerAttributes = {
  title?: InputMaybe<Scalars['String']['input']>;
};

export type WbHandlerPagination = {
  __typename?: 'WbHandlerPagination';
  exportPath?: Maybe<Scalars['String']['output']>;
  items: Array<WbHandler>;
  total: Scalars['Int']['output'];
};

export type WbMessage = Node & {
  __typename?: 'WbMessage';
  addedHandlers?: Maybe<Array<PublicWbHandler>>;
  content?: Maybe<Scalars['String']['output']>;
  files: Array<Attachment>;
  /** ID of the object. */
  id: Scalars['ID']['output'];
  internal: Scalars['Boolean']['output'];
  newCategory?: Maybe<WbReportCategoryEnum>;
  newType?: Maybe<WbReportTypeEnum>;
  oldCategory?: Maybe<WbReportCategoryEnum>;
  oldType?: Maybe<WbReportTypeEnum>;
  removedHandlers?: Maybe<Array<PublicWbHandler>>;
  seen: Scalars['Boolean']['output'];
  sentAt: Scalars['String']['output'];
  type: WbMessageTypeEnum;
  wbHandler?: Maybe<PublicWbHandler>;
};

export type WbMessageAttributes = {
  content?: InputMaybe<Scalars['String']['input']>;
  files?: InputMaybe<Array<Base64AttachmentAttributes>>;
  internal: Scalars['Boolean']['input'];
};

export enum WbMessageTypeEnum {
  CategoryChanged = 'CATEGORY_CHANGED',
  HandlersChanged = 'HANDLERS_CHANGED',
  Message = 'MESSAGE',
  ReportClosed = 'REPORT_CLOSED',
  ReportDeleted = 'REPORT_DELETED',
  ReportReopened = 'REPORT_REOPENED',
  TypeChanged = 'TYPE_CHANGED'
}

export type WbReport = Node & {
  __typename?: 'WbReport';
  abandoned: Scalars['Boolean']['output'];
  action?: Maybe<Scalars['String']['output']>;
  category?: Maybe<WbReportCategoryEnum>;
  closedAt?: Maybe<Scalars['String']['output']>;
  closedBy?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['String']['output']>;
  deletedBy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  downloadPath?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  files: Array<Attachment>;
  firstName?: Maybe<Scalars['String']['output']>;
  /** ID of the object. */
  id: Scalars['ID']['output'];
  isAccessible: Scalars['Boolean']['output'];
  lastInteractionAt: Scalars['String']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  people?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<PhoneNumberStruct>;
  privacy: WbReportPrivacyEnum;
  reportNumber?: Maybe<Scalars['String']['output']>;
  routineGuidelines: Array<Attachment>;
  status: WbReportStatusEnum;
  submittedAt?: Maybe<Scalars['String']['output']>;
  timing?: Maybe<Scalars['String']['output']>;
  type: WbReportTypeEnum;
  wbHandlers: Array<PublicWbHandler>;
  wbMessages: Array<WbMessage>;
  wbMessagesCount: Scalars['Int']['output'];
};

export type WbReportAttributes = {
  category?: InputMaybe<WbReportCategoryEnum>;
  type?: InputMaybe<WbReportTypeEnum>;
  wbHandlerIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export enum WbReportCategoryEnum {
  BriberyCorruptionConflictOfInterest = 'BRIBERY_CORRUPTION_CONFLICT_OF_INTEREST',
  BullyingHarassmentThreatsViolence = 'BULLYING_HARASSMENT_THREATS_VIOLENCE',
  DangerousPhysicalConditions = 'DANGEROUS_PHYSICAL_CONDITIONS',
  DrugOrDopingAbuse = 'DRUG_OR_DOPING_ABUSE',
  FinancialMisconductEmbezzlementTheft = 'FINANCIAL_MISCONDUCT_EMBEZZLEMENT_THEFT',
  MishandlingOfPersonalDataDataLeaks = 'MISHANDLING_OF_PERSONAL_DATA_DATA_LEAKS',
  Other = 'OTHER',
  PollutionViolationOfEnvironmentalLegislation = 'POLLUTION_VIOLATION_OF_ENVIRONMENTAL_LEGISLATION',
  RacismAndDiscrimination = 'RACISM_AND_DISCRIMINATION',
  SexualHarassmentAndAbuse = 'SEXUAL_HARASSMENT_AND_ABUSE'
}

export type WbReportPagination = {
  __typename?: 'WbReportPagination';
  exportPath?: Maybe<Scalars['String']['output']>;
  items: Array<WbReport>;
  total: Scalars['Int']['output'];
};

export enum WbReportPrivacyEnum {
  Anonymous = 'ANONYMOUS',
  Confidential = 'CONFIDENTIAL'
}

export enum WbReportStatusEnum {
  Closed = 'CLOSED',
  Deleted = 'DELETED',
  Draft = 'DRAFT',
  InProgress = 'IN_PROGRESS',
  Submitted = 'SUBMITTED'
}

export enum WbReportTypeEnum {
  Feedback = 'FEEDBACK',
  Whistleblowing = 'WHISTLEBLOWING'
}

export type WbReportsFilter = {
  category?: InputMaybe<WbReportCategoryEnum>;
  reportNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<WbReportStatusEnum>;
  type?: InputMaybe<WbReportTypeEnum>;
};

export enum WbReportsSortByEnum {
  LastInteractionAtAsc = 'LAST_INTERACTION_AT_ASC',
  LastInteractionAtDesc = 'LAST_INTERACTION_AT_DESC',
  ReportNumberAsc = 'REPORT_NUMBER_ASC',
  ReportNumberDesc = 'REPORT_NUMBER_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  SubmittedAtAsc = 'SUBMITTED_AT_ASC',
  SubmittedAtDesc = 'SUBMITTED_AT_DESC'
}

export type WbSettings = {
  __typename?: 'WbSettings';
  description?: Maybe<Scalars['String']['output']>;
  feedbackDescription?: Maybe<Scalars['String']['output']>;
  feedbackEnabled: Scalars['Boolean']['output'];
  feedbackLink?: Maybe<Scalars['String']['output']>;
  feedbackReadMoreLink?: Maybe<Scalars['String']['output']>;
  whistleblowingDescription?: Maybe<Scalars['String']['output']>;
  whistleblowingReadMoreLink?: Maybe<Scalars['String']['output']>;
};

export type WbSettingsAttributes = {
  description?: InputMaybe<Scalars['String']['input']>;
  feedbackDescription?: InputMaybe<Scalars['String']['input']>;
  feedbackEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  feedbackLink?: InputMaybe<Scalars['String']['input']>;
  feedbackReadMoreLink?: InputMaybe<Scalars['String']['input']>;
  whistleblowingDescription?: InputMaybe<Scalars['String']['input']>;
  whistleblowingReadMoreLink?: InputMaybe<Scalars['String']['input']>;
};

export type WorkInvoiceLine = InvoiceLine & Node & {
  __typename?: 'WorkInvoiceLine';
  amount: Scalars['Int']['output'];
  collapsed: Scalars['Boolean']['output'];
  dependentLines: Array<InvoiceLine>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  invoice: Invoice;
  lineType: LineTypeEnum;
  occupationCode: OccupationCode;
  quantity: Scalars['Float']['output'];
  remainingQuantity: Scalars['Float']['output'];
  unitPrice: Scalars['Int']['output'];
  vat: Scalars['Int']['output'];
  vatRate: Scalars['Float']['output'];
  workReport: WorkReport;
};

export type WorkReport = {
  endDate: Scalars['String']['output'];
  startDate: Scalars['String']['output'];
  type: WorkReportTypeEnum;
};

export type WorkReportAttributes = {
  endDate: Scalars['String']['input'];
  endTime?: InputMaybe<Scalars['String']['input']>;
  hours?: InputMaybe<Scalars['Float']['input']>;
  startDate: Scalars['String']['input'];
  startTime?: InputMaybe<Scalars['String']['input']>;
  type: WorkReportTypeEnum;
};

export enum WorkReportTypeEnum {
  Task = 'TASK',
  Time = 'TIME'
}
